function calculateNextDate(date, periodicity) {
  const next = new Date(date.getTime());
  if (periodicity === 'weekly') {
    next.setDate(next.getDate() + 7);
  } else if (periodicity === 'biweekly') {
    next.setDate(next.getDate() + 14);
  } else if (periodicity === 'monthly') {
    return nextMonthSameWeekDay(date);
  }
  return next;
}

function nextMonthSameWeekDay(date) {
  const currentMonth = date.getMonth();
  let nextDate = new Date(date.getTime());
  nextDate.setMonth(currentMonth + 1);

  if (nextDate.getMonth() > currentMonth + 1) {
    nextDate.setDate(0);
  }

  const weekDayDiff = date.getDay() - nextDate.getDay();
  nextDate.setDate(nextDate.getDate() + weekDayDiff);
  if (nextDate.getMonth() == currentMonth) {
    nextDate.setDate(nextDate.getDate() + 7);
  }

  return nextDate;
}

module.exports = calculateNextDate;
