import axios from 'axios';

import ClientError from './client-error.js';

export default (baseUrl, getToken) => {
  async function createPatient(patientData) {
    try {
      const response = await axios({
        method: 'post',
        url: `${baseUrl}/patients`,
        data: patientData,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, patient: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on creating patient', response);
      }
    }
  }

  async function updatePatient(id, patientData) {
    try {
      const response = await axios({
        method: 'put',
        url: `${baseUrl}/patients/${id}`,
        data: patientData,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, patient: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on updating patient', response);
      }
    }
  }

  async function listPatients(page, pageSize, term) {
    let url = `${baseUrl}/patients?page=${page}&pageSize=${pageSize}`;
    if (term) {
      url = `${url}&term=${term}`;
    }

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on listing patient', response);
      }
    }
  }

  async function deletePatient(id) {
    try {
      await axios({
        method: 'delete',
        url: `${baseUrl}/patients/${id}`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on deleting patient', response);
      }
    }
  }

  async function getPatient(id) {
    try {
      const response = await axios({
        method: 'get',
        url: `${baseUrl}/patients/${id}`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on getting patient', response);
      }
    }
  }

  async function getAppointments(id, page, pageSize) {
    try {
      const response = await axios({
        method: 'get',
        url: `${baseUrl}/patients/${id}/appointments?page=${page}&pageSize=${pageSize}`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError(
          'Error on getting patient appointments',
          response,
        );
      }
    }
  }

  async function createPatientRecord(id, recordData) {
    try {
      const response = await axios({
        method: 'post',
        url: `${baseUrl}/patients/${id}/records`,
        data: recordData,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, patient: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on creating patient record', response);
      }
    }
  }

  async function findPatientRecord(patientId, recordId) {
    try {
      const response = await axios({
        method: 'get',
        url: `${baseUrl}/patients/${patientId}/records/${recordId}`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, patientRecord: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on getting patient record', response);
      }
    }
  }

  async function deletePatientRecord(patientId, recordId) {
    try {
      await axios({
        method: 'delete',
        url: `${baseUrl}/patients/${patientId}/records/${recordId}`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on getting patient record', response);
      }
    }
  }

  return {
    createPatient,
    listPatients,
    deletePatient,
    getPatient,
    updatePatient,
    getAppointments,
    createPatientRecord,
    findPatientRecord,
    deletePatientRecord,
  };
};
