<script setup>
import TopMenu from './components/TopMenu.vue';
import Dialog from './components/dialog/Dialog.vue';
import Toast from './components/toast/Toast.vue';
import getCurrentUser from './util/current-user';
import mixpanel from './util/mixpanel';
import sessionStorage from './util/storage/session-storage';
</script>

<script>
export default {
  methods: {
    isLoggedIn() {
      return !!sessionStorage.getToken();
    },
    showMenu() {
      return !this.$route.meta.public && this.isLoggedIn();
    },
  },
  watch: {
    $route() {
      this.$forceUpdate();
    },
  },
  mounted() {
    if (!this.isLoggedIn()) {
      if (!this.$route.meta.public) this.$router.push('/login');
    } else {
      const currentUser = getCurrentUser();
      mixpanel.identify(currentUser.userId, {
        email: currentUser.email,
        accountId: currentUser.accountId,
        accountType: currentUser.accountType,
        name: currentUser.name,
        specialty: currentUser.specialty,
      });
    }
  },
};
</script>

<template>
  <div class="root">
    <Toast />
    <Dialog />
    <TopMenu v-if="showMenu()" />
    <div class="main">
      <RouterView class="content" />
    </div>
  </div>
</template>

<style lang="scss">
.root {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.main {
  max-width: 1200px;
  width: 100%;
  align-self: center;

  @media screen and (min-width: 600px) {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    margin-top: 60px;
    padding: 20px 0;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin-top: 70px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  width: 100%;
}
</style>
