<style lang="scss">
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 0.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: '';
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

.spin-container {
  padding-top: 50px;
  padding-bottom: 50px;
  display: block;
  flex-flow: column;
  align-content: center;
  justify-content: center;
}
</style>

<template>
  <div class="spin-container">
    <div class="spin"></div>
  </div>
</template>
