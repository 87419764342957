import jwtDecode from 'jwt-decode';

import sessionStorage from '../util/storage/session-storage';

function getCurrentUser() {
  const token = sessionStorage.getToken();
  const decodedToken = jwtDecode(token);

  return decodedToken;
}

export default getCurrentUser;
