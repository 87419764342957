import axios from 'axios';

import ClientError from './client-error.js';

export default baseUrl => {
  async function createAccount(accountData) {
    try {
      const response = await axios({
        method: 'post',
        url: `${baseUrl}/accounts/signup`,
        data: accountData,
      });

      return {
        created: true,
        user: response.data.user,
        token: response.data.token,
      };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { created: false, errors: response.data };
      } else {
        throw new ClientError('Error on creating account', response);
      }
    }
  }

  async function requestRecover(email) {
    try {
      await axios({
        method: 'post',
        url: `${baseUrl}/accounts/request-recover`,
        data: { email },
      });

      return {
        ok: true,
      };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on requesting recover', response);
      }
    }
  }

  async function resetPassword(code, newPassword) {
    try {
      await axios({
        method: 'post',
        url: `${baseUrl}/accounts/reset-password`,
        data: { code, newPassword },
      });

      return {
        ok: true,
      };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on reseting password', response);
      }
    }
  }

  return {
    createAccount,
    requestRecover,
    resetPassword,
  };
};
