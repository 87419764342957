export default {
  'pt-br': {
    'email already been taken': 'Email já cadastrado',
    'Invalid user or password': 'Usuário ou senha inválidos',
    pending: 'pendente',
    delayed: 'atrasado',
    complete: 'concluido',
    canceled: 'cancelado',
    'content is required': 'conteúdo é obrigatório',
    'keywords are required': 'palavras chave é obrigatório',
    'description cannot be blank': 'Descrição é obrigatória',
    'value cannot be blank': 'Valor é obrigatória',
    'dueDate cannot be blank': 'Data de vencimento é obrigatória',
    'value is required': 'Valor é obrigatório',
    'dueDate is required': 'Data é obrigatória',
    'type must be either "income" or "outcome"':
      'infomre se o lançamento é uma receita ou uma despesa',
    'user not found': 'Usuário não encontrado',
    'recover code not found': 'Código de recuperacão inválido',
  },
};
