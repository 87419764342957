<script setup>
import { nanoid } from 'nanoid';

import PaginatedList from '../../components/PaginatedList.vue';
import AppointmentCard from '../../components/appointments/AppointmentCard.vue';
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="ms-2 mt-2">Consutas Atrasadas</h4>
      <PaginatedList
        apiPath="/appointments/late"
        class="mt-4"
        :key="delayedAppointmentsKey">
        <template #item="appointment">
          <AppointmentCard
            :appointment="appointment"
            @appointmentUpdated="updateList()" />
        </template>
      </PaginatedList>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      delayedAppointmentsKey: nanoid(),
    };
  },
  methods: {
    updateList() {
      this.delayedAppointmentsKey = nanoid();
    },
  },
};
</script>
