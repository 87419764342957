import axios from 'axios';

import accountClient from './account-client.js';
import appointmentsClient from './appointments-client.js';
import financialClient from './financial-client.js';
import patientsClient from './patients-client.js';
import purchaseClient from './purchases-client.js';
import sessionClient from './session-client.js';
import usersClient from './users-client.js';

export default (basePath, tokenProvider) => {
  const responseHandlers = [];
  return {
    client() {
      const client = axios.create({
        baseURL: basePath,
        headers: {
          'x-auth': tokenProvider(),
        },
      });

      responseHandlers.forEach(handlers => {
        client.interceptors.response.use(...handlers);
      });

      return client;
    },
    addResponseHandler(okFn, errFn) {
      responseHandlers.push([okFn, errFn]);
      axios.interceptors.response.use(okFn, errFn);
    },
    account: accountClient(basePath),
    session: sessionClient(basePath),
    patients: patientsClient(basePath, tokenProvider),
    users: usersClient(basePath, tokenProvider),
    purchases: purchaseClient(basePath, tokenProvider),
    appointments: appointmentsClient(basePath, tokenProvider),
    financial: financialClient(basePath, tokenProvider),
  };
};
