<script setup></script>

<template>
  <div class="input-tag form-control">
    <span class="tag" v-for="tag in modelValue" :key="tag">
      {{ tag }}
      <span class="close" v-on:click="deleteTag(tag)">x</span>
    </span>
    <input
      type="text"
      :id="id"
      :placeholder="placeholder"
      v-model="newTag"
      v-on:keydown="validateKey"
      v-on:blur="addTag"
      v-on:keydown.delete="onDeletePressed" />
  </div>
</template>

<script>
export default {
  props: ['id', 'placeholder', 'modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      newTag: '',
      tags: [],
    };
  },
  mounted() {
    this.tags = [...this.modelValue];
  },
  methods: {
    validateKey(event) {
      // 13 = enter
      // 188 = comma
      const keys = [13, 188];
      if (keys.indexOf(event.keyCode) !== -1) {
        this.addTag();
        event.preventDefault();
      }
    },
    addTag() {
      const newTag = this.newTag.trim();
      if (newTag.length > 0) {
        if (this.tags.indexOf(newTag) == -1) {
          this.tags.push(newTag);
          this.$emit('update:modelValue', this.tags);
          this.newTag = '';
        } else {
          this.newTag = '';
        }
      }
    },
    onDeletePressed() {
      if (this.newTag.length == 0) {
        this.tags.pop();
        this.$emit('update:modelValue', this.tags);
      }
    },
    deleteTag(tag) {
      this.tags = this.tags.filter(t => t != tag);
      this.$emit('update:modelValue', this.tags);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-tag {
  display: flex;
  flex-flow: row;

  .tag {
    margin: 0 2px;
    padding: 0 10px;
    padding-right: 18px;
    border: 1px solid var(--contrast-dark);
    border-radius: 5px;
    background-color: var(--contrast);
    color: #fff;
    line-height: 20px;

    &:last-of-type {
      margin-right: 10px;
    }

    .close {
      position: absolute;
      margin-left: 5px;
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  &:has(input:focus-visible) {
    color: #212529;
    background-color: #ffffff;
    border-color: #9ebcde;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(61, 120, 188, 0.25);
  }

  input {
    padding: 0;
    margin: 0;
    border: none;
    flex-grow: 1;

    &:focus-visible {
      outline: none;
    }
  }
}
</style>
