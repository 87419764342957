// Credit: ChatGPT, a large language model trained by OpenAI.

export default function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, ''); // remove all non-digit characters
  if (cpf.length !== 11 || cpf.match(/(\d)\1{10}/)) {
    // check if CPF has 11 digits and if all digits are the same
    return false;
  }
  const sum1 = cpf
    .slice(0, 9)
    .split('')
    .map(Number)
    .reduce((acc, curr, i) => acc + curr * (10 - i), 0);
  const mod1 = sum1 % 11;
  const dig1 = mod1 < 2 ? 0 : 11 - mod1;
  if (dig1 !== parseInt(cpf.charAt(9))) {
    return false;
  }
  const sum2 = cpf
    .slice(0, 10)
    .split('')
    .map(Number)
    .reduce((acc, curr, i) => acc + curr * (11 - i), 0);
  const mod2 = sum2 % 11;
  const dig2 = mod2 < 2 ? 0 : 11 - mod2;
  if (dig2 !== parseInt(cpf.charAt(10))) {
    return false;
  }
  return true;
}
