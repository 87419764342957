<script setup>
import { Toast } from 'bootstrap';
import * as uuid from 'uuid';

import { eventEmitter } from './toast-plugin';
</script>

<script>
export default {
  mounted() {
    eventEmitter.on('toast', options => {
      const toast = { id: uuid.v4(), ...options };
      this.toasts.push(toast);
      this.newToasts.push(toast.id);
    });
  },
  updated() {
    while (this.newToasts.length > 0) {
      const toastId = this.newToasts.shift();
      const el = this.$refs[`toast-${toastId}`][0];
      const toast = Toast.getOrCreateInstance(el);
      toast.show();
      const toastData = this.toasts.filter(t => t.id == toastId)[0];
      setTimeout(
        () => {
          this.toasts = this.toasts.filter(toast => toast.id != toastId);
        },
        (toastData.life || 5000) + 1000,
      );
    }
  },
  data() {
    return {
      newToasts: [],
      toasts: [],
    };
  },
  methods: {
    toastClass(toast) {
      return `toast-${toast.severity}`;
    },
  },
};
</script>
<template>
  <div class="toasts">
    <div
      v-for="toast in toasts"
      :key="toast.id"
      :ref="`toast-${toast.id}`"
      class="toast align-items-center"
      :class="toastClass(toast)"
      role="alert"
      :data-bs-delay="toast.life">
      <div class="toast-header" v-if="toast.title">
        <strong class="me-auto">{{ toast.title }}</strong>
        <button
          type="button"
          class="btn-close me-2 m-auto"
          data-bs-dismiss="toast"></button>
      </div>
      <div class="toast-body">
        <div class="d-flex">
          {{ toast.message }}
          <button
            type="button"
            class="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            v-if="!toast.title"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.toasts {
  position: absolute;
  z-index: 9000;
  right: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  @media screen and (max-width: 400px) {
    top: 0;
  }

  .toast {
    margin: 10px;

    @media screen and (max-width: 400px) {
      width: 100%;
      margin: 0;
      margin-top: 30px;
    }
  }

  .toast-success {
    background-color: var(--toast-bg-success);
    border: 1px solid var(--toast-border-success);
    color: var(--toast-color-success);

    .toast-header {
      background-color: var(--toast-bg-success);
      border-bottom: 1px solid var(--toast-border-success);
      color: var(--toast-color-success);
    }
  }

  .toast-info {
    background-color: var(--toast-bg-info);
    border: 1px solid var(--toast-border-info);
    color: var(--toast-color-info);

    .toast-header {
      background-color: var(--toast-bg-info);
      border-bottom: 1px solid var(--toast-border-info);
      color: var(--toast-color-info);
    }
  }

  .toast-warning {
    background-color: var(--toast-bg-warning);
    border: 1px solid var(--toast-border-warning);
    color: var(--toast-color-warning);

    .toast-header {
      background-color: var(--toast-bg-warning);
      border-bottom: 1px solid var(--toast-border-warning);
      color: var(--toast-color-warning);
    }
  }

  .toast-danger {
    background-color: var(--toast-bg-danger);
    border: 1px solid var(--toast-border-danger);
    color: var(--toast-color-danger);

    .toast-header {
      background-color: var(--toast-bg-danger);
      border-bottom: 1px solid var(--toast-border-danger);
      color: var(--toast-color-danger);
    }
  }
}
</style>
