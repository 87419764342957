<script setup>
import eventBus from '../util/events/event-bus';
</script>

<script>
export default {
  props: ['items'],
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
  mounted() {
    eventBus.$on('menu-button-clicked', () => {
      this.toggleMenu();
    });
  },
};
</script>

<template>
  <div class="side-menu-root">
    <div class="side-menu" :class="{ 'side-menu-open': menuOpen }">
      <ul class="main-menu">
        <li v-for="item in items" :key="item.label">
          <router-link :to="item.url" v-on:click="toggleMenu"
            ><i :class="item.icon"></i> {{ item.label }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.side-menu-root {
  @media screen and (max-width: 600px) {
    position: fixed;
  }

  height: 100%;
  z-index: 1000;
}

.side-menu {
  display: flex;
  flex-flow: column;
  height: 100%;
  background: #5892d5;
  max-width: 300px;
  min-width: 200px;
  margin-top: 60px;

  @media screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    margin-left: -300px;
    transition: 1s;
  }

  &.side-menu-open {
    margin-left: 0px;
    transition: 0.5s;
  }

  ul {
    list-style: none;
    padding: 0;
    width: 100%;

    li {
      display: block;
      height: 40px;
      line-height: 40px;
      color: #efefef;
      font-size: 16px;

      a {
        padding: 0 15px;
        display: block;
        color: inherit;
        text-decoration: none;
        width: 100%;
        height: 100%;
      }

      &:hover {
        background-color: rgb(117, 186, 238);
      }
    }
  }

  .main-menu {
    flex-grow: 1;
  }
}
</style>
