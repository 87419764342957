<script setup>
import apiClient from '../../util/api-client';

import FinanceModalButton from './FinanceModalButton.vue';
</script>

<template>
  <div class="finance-actions-container flex-fill">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :class="cleanButtonClass()">
        Ações
      </button>
      <ul class="dropdown-menu">
        <span v-if="finance.paidAt">
          <li>
            <a
              class="dropdown-item"
              href="#"
              v-on:click.prevent="unpaidFinancialRecord(finance.id)"
              >Marcar com pendente</a
            >
          </li>
        </span>
        <span v-if="!finance.paidAt">
          <li>
            <a
              class="dropdown-item"
              href="#"
              v-on:click.prevent="paidFinancialRecord(finance.id)"
              >Marcar como pago</a
            >
          </li>
        </span>
        <li>
          <a
            class="dropdown-item"
            href="#"
            v-on:click.prevent="deleteFinancialRecord(finance.id)"
            >Excluir</a
          >
        </li>
        <li>
          <FinanceModalButton
            :finance-id="finance.id"
            :type="finance.type"
            @financeUpdated="onFinanceUpdated"
            >Editar</FinanceModalButton
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['finance', 'cleanButton'],
  methods: {
    async paidFinancialRecord(financialRecord) {
      let message = 'Deseja marcar o rigistro financeiro como pago?';
      this.$confirm({
        message: message,
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-success',
        acceptLabel: 'Marcar como pago',
        rejectLabel: 'Cancelar',
        onAccept: async () => {
          const result =
            await apiClient.financial.paidFinancialRecord(financialRecord);
          if (result.ok) {
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Lançamento pago com sucesso!',
              life: 3000,
            });
            this.$emit('finance-updated', financialRecord);
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
        onReject: () => {},
      });
    },

    async unpaidFinancialRecord(financialRecord) {
      let message = 'Deseja marcar registro financeiro como pendente?';
      this.$confirm({
        message: message,
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-warning',
        acceptLabel: 'Marcar como pendente',
        rejectLabel: 'Cancelar',
        onAccept: async () => {
          const result =
            await apiClient.financial.unpaidFinancialRecord(financialRecord);
          if (result.ok) {
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Lançamento marcado como pendente!',
              life: 3000,
            });
            this.$emit('finance-updated', financialRecord);
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
        onReject: () => {},
      });
    },

    async deleteFinancialRecord(financialRecord) {
      let message = 'Confirmar exclusão de registro financeiro?';
      this.$confirm({
        message: message,
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-danger',
        acceptLabel: 'Excluir',
        rejectLabel: 'Cancelar',
        onAccept: async () => {
          const result =
            await apiClient.financial.deleteFinancialRecord(financialRecord);
          if (result.ok) {
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Lançamento excluido com sucesso!',
              life: 3000,
            });
            this.$emit('finance-updated', financialRecord);
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
        onReject: () => {},
      });
    },
    onFinanceUpdated(finance) {
      this.$emit('finance-updated', finance);
    },
    cleanButtonClass() {
      if (this.cleanButton) return 'clean-button';
    },
  },
};
</script>

<style lang="scss">
.finance-actions-container {
  .clean-button {
    border: none;
  }
}
</style>
