<script setup>
import { nanoid } from 'nanoid';
import Emiter from 'tiny-emitter';

import Loader from '../../components/Loader.vue';
import PaginatedList from '../../components/PaginatedList.vue';
import Pagination from '../../components/Pagination.vue';
import AppointmentActions from '../../components/appointments/AppointmentActions.vue';
import FinanceActions from '../../components/finance/FinanceActions.vue';
import apiClient from '../../util/api-client';
</script>

<script>
export default {
  async mounted() {
    this.loading = true;
    const patientId = this.$route.params.id;
    const response = await apiClient.patients.getPatient(patientId);
    this.patient = response.data;

    this.navigate(this.tabParam || 'appointments');
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      patient: {},
      activeTab: null,
      appointments: null,
      eventBus: new Emiter(),
      financeListKey: nanoid(),
    };
  },
  methods: {
    getAddress() {
      let address = '';
      if (this.patient.address) {
        address = this.patient.address;
      }

      if (this.patient.city) {
        address = address
          ? `${address} - ${this.patient.city}`
          : this.patient.city;
      }

      if (this.patient.state) {
        address = address
          ? `${address} - ${this.patient.state}`
          : this.patient.state;
      }

      if (this.patient.zipCode) {
        address = address
          ? `${address} - ${this.patient.zipCode}`
          : this.patient.zipCode;
      }

      return address;
    },
    async loadAppointments() {
      const page = this.currentPage || 1;
      const pageSize = this.pageSize || 10;
      const result = await apiClient.patients.getAppointments(
        this.patient.id,
        page,
        pageSize,
      );
      if (result.ok) {
        this.appointments = result.data;
      }
    },
    navigate(tab) {
      let page = this.currentPage;
      if (tab != this.activeTab) {
        page = 1;
      }
      this.activeTab = tab;
      const currentQuery = this.$route.query;
      delete currentQuery.tab;
      delete currentQuery.page;
      this.$router.replace({
        path: this.$route.path,
        query: { tab: tab, page: page, ...currentQuery },
      });
      this.loadData();
    },
    tabClicked(e) {
      e.preventDefault();
      const dest = e.target.getAttribute('data-navigation');
      this.navigate(dest);
    },
    async loadData() {
      switch (this.activeTab) {
        case 'appointments':
          await this.loadAppointments();
          break;
        case 'financial':
          break;
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    calcDuration(startAt, endAt) {
      return (new Date(endAt).getTime() - new Date(startAt).getTime()) / 60000;
    },
    getStatus(appointment) {
      if (
        appointment.status === 'pending' &&
        new Date() > new Date(appointment.startAt)
      )
        return 'delayed';
      return appointment.status;
    },
    statusClass(appointment) {
      switch (appointment.status) {
        case 'pending':
          if (new Date() > new Date(appointment.startAt)) return 'bg-danger';
          return 'bg-warning';
        case 'complete':
          return 'bg-success';
        default:
          return 'bg-secondary';
      }
    },
    getFinancialStatus(financialRecord) {
      if (financialRecord.paidAt) return 'pago';
      if (new Date(financialRecord.dueDate).getTime() < new Date().getTime())
        return 'atrasado';
      return 'pendente';
    },
    getFinancialStatusClass(status) {
      switch (status) {
        case 'atrasado':
          return 'bg-danger';
        case 'pago':
          return 'bg-success';
        default:
          return 'bg-warning';
      }
    },
    formatCurrency(valueInCents) {
      const value = Math.abs(valueInCents / 100);
      var formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return formater.format(value);
    },
    updateFinanceList() {
      this.financeListKey = nanoid();
    },
    localDate(dateString) {
      const date = new Date(dateString);
      return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    },
  },
  computed: {
    tabParam() {
      return this.$route.query.tab;
    },
    currentPage() {
      return this.$route.query.page;
    },
    pageSize() {
      return this.$route.query.pageSize;
    },
    hasAppointments() {
      return this.appointments?.appointments?.length > 0;
    },
    patientAge() {
      const parts = this.patient.birthday.split('/');
      if (parts.length === 3 && parts[2].length === 4) {
        const startDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
        const endDate = new Date(); // Current date
        const differenceInMilliseconds = endDate - startDate;
        const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Considering leap years
        const age = Math.floor(differenceInMilliseconds / millisecondsInYear);
        if (age && !isNaN(age) && age < 110 && age >= 0) {
          return age;
        }
      }
      return null;
    },
  },
  watch: {
    currentPage() {
      this.navigate(this.activeTab);
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="patient-card">
        <Loader v-if="loading" />
        <div
          class="d-flex flex-row justify-content-center align-items-center d-md-none mb-4 mt-4">
          <h2 class="patient-name flex-grow-1 m-0">{{ patient.name }}</h2>
          <div>
            <button
              class="btn btn-outline-primary me-1 btn-sm"
              @click="$router.push(`/patients/${patient.id}/edit`)">
              <i class="bi bi-pencil"></i>
            </button>
          </div>
        </div>
        <div v-if="!loading" class="patient-view">
          <div class="patient-info">
            <div
              class="d-flex flex-row justify-content-center align-items-center mt-4 mb-4">
              <h2 class="patient-name flex-grow-1 m-0 me-3">
                {{ patient.name }}
              </h2>
              <button
                class="btn btn-outline-primary me-1 btn-sm"
                @click="$router.push(`/patients/${patient.id}/edit`)">
                <i class="bi bi-pencil"></i>
              </button>
            </div>
            <div class="info"><i class="bi bi-at"></i> {{ patient.email }}</div>
            <div class="info">
              <i class="bi bi-phone"></i> {{ patient.phone }}
            </div>
            <div class="info" v-if="patient.birthday">
              <i class="bi bi-star"></i> {{ patient.birthday }} -
              {{ patientAge }} anos
            </div>
            <div class="info" v-if="patient.cpf">
              <i class="bi bi-person-vcard"></i> {{ patient.cpf }}
            </div>
            <div class="info" v-if="patient.rg">
              <i class="bi bi-person-vcard"></i> {{ patient.rg }}
            </div>
            <div class="info" v-if="getAddress()">
              <i class="bi bi-geo-alt"></i> {{ getAddress() }}
            </div>
          </div>
          <div class="patient-page">
            <ul class="nav nav-tabs">
              <li class="nav-item d-block d-md-none">
                <a
                  class="nav-link"
                  :class="activeTab === 'info' ? 'active' : ''"
                  href="#"
                  data-navigation="info"
                  v-on:click="tabClicked"
                  >Informações</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="activeTab === 'appointments' ? 'active' : ''"
                  href="#"
                  data-navigation="appointments"
                  v-on:click="tabClicked"
                  >Consultas</a
                >
              </li>
              <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  :class="activeTab === 'records' ? 'active' : ''"
                  href="#"
                  data-navigation="records"
                  v-on:click="tabClicked"
                  >Documentos</a
                >
              </li> -->
              <li class="nav-item">
                <a
                  class="nav-link"
                  :class="activeTab === 'financial' ? 'active' : ''"
                  href="#"
                  data-navigation="financial"
                  v-on:click="tabClicked"
                  >Financeiro</a
                >
              </li>
            </ul>
            <div v-if="activeTab === 'info'">
              <div class="mt-3">
                <div class="info">
                  <i class="bi bi-at"></i> {{ patient.email }}
                </div>
                <div class="info">
                  <i class="bi bi-phone"></i> {{ patient.phone }}
                </div>
                <div class="info" v-if="patient.birthday">
                  <i class="bi bi-star"></i> {{ patient.birthday }} -
                  {{ patientAge }} anos
                </div>
                <div class="info" v-if="patient.cpf">
                  <i class="bi bi-person-vcard"></i> {{ patient.cpf }}
                </div>
                <div class="info" v-if="patient.rg">
                  <i class="bi bi-person-vcard"></i> {{ patient.rg }}
                </div>
                <div class="info" v-if="getAddress()">
                  <i class="bi bi-geo-alt"></i> {{ getAddress() }}
                </div>
              </div>
            </div>
            <div
              v-if="activeTab === 'appointments'"
              class="appointments d-flex flex-column">
              <div v-if="!hasAppointments">
                <div class="blankslate">
                  <p>
                    Sem consultas cadastradas para o paciente? Registre agora
                    mesmo!
                  </p>
                  <router-link
                    class="btn btn-primary"
                    :to="{
                      path: '/appointments/create/2',
                      query: {
                        patientId: patient.id,
                        backTo: $route.fullPath,
                      },
                    }"
                    >Nova consulta</router-link
                  >
                </div>
              </div>
              <div v-if="hasAppointments">
                <div class="mb-2 mt-2">
                  <router-link
                    class="btn btn-primary"
                    :to="{
                      path: '/appointments/create/2',
                      query: {
                        patientId: patient.id,
                        backTo: $route.fullPath,
                      },
                    }"
                    >Nova consulta</router-link
                  >
                </div>
                <div
                  class="header d-none d-md-flex flex-column flex-md-row justify-content-between p-2">
                  <div class="col-5">Data</div>
                  <div class="col-2">Duração</div>
                  <div class="col-2">Status</div>
                  <div class="col-3"></div>
                </div>
                <div
                  v-for="appointment in appointments?.appointments"
                  :key="appointment.id"
                  class="appointment d-flex flex-column flex-md-row justify-content-between p-2">
                  <div class="col-12 col-md-5">
                    {{ formatDate(appointment.startAt) }}
                    <span v-if="appointment.purchaseNumber">
                      <span class="badge badge-height text-bg-light"
                        >pacote {{ appointment.purchaseNumber }} - ({{
                          appointment.number
                        }}
                        de {{ appointment.purchaseTotal }})</span
                      >
                    </span>
                  </div>
                  <div class="col-2 d-none d-md-block">
                    {{ calcDuration(appointment.startAt, appointment.endAt) }}
                    min
                  </div>
                  <div class="col-2 d-none d-md-block">
                    <span
                      class="badge badge-height"
                      :class="statusClass(appointment)"
                      >{{ $t(getStatus(appointment)) }}</span
                    >
                  </div>
                  <div class="col-3 d-none d-md-block flex-fill">
                    <AppointmentActions
                      :appointment="appointment"
                      :eventBus="eventBus"
                      @setComplete="loadData"
                      @setPending="loadData"
                      @rescheduled="loadData"
                      @setCanceled="loadData" />
                  </div>
                  <div class="col-12 d-block d-md-none">
                    <div class="row">
                      <div class="col-8">
                        <span
                          >{{
                            calcDuration(appointment.startAt, appointment.endAt)
                          }}
                          min
                        </span>
                        <span
                          class="badge badge-height"
                          :class="statusClass(appointment)"
                          >{{ $t(getStatus(appointment)) }}</span
                        >
                      </div>
                      <AppointmentActions
                        :appointment="appointment"
                        :eventBus="eventBus"
                        @setComplete="loadData"
                        @setPending="loadData"
                        @rescheduled="loadData"
                        @setCanceled="loadData"
                        class="col-4" />
                    </div>
                  </div>
                </div>
                <Pagination
                  v-if="appointments?.appointments"
                  :url="$route.path"
                  :current-page="appointments.page"
                  :page-size="appointments.pageSize"
                  :total-items="appointments.total" />
              </div>
            </div>
            <div v-if="activeTab === 'records'">
              <PaginatedList
                :api-path="`patients/${patient.id}/records`"
                class="mt-3">
                <template #empty>
                  <div class="blankslate">
                    <p>Não há documentos anexados a consultas.</p>
                    <router-link
                      class="btn btn-primary"
                      :to="{
                        path: '/appointments/create/2',
                        query: {
                          patientId: patient.id,
                          backTo: $route.fullPath,
                        },
                      }"
                      >Nova consulta</router-link
                    >
                  </div>
                </template>
                <template #header>
                  <div
                    class="header d-none d-md-flex flex-column flex-md-row justify-content-between p-2">
                    <div class="col-4">Título</div>
                    <div class="col-4">Palavras chave</div>
                    <div class="col-2">Data</div>
                    <div class="col-2"></div>
                  </div>
                </template>
                <template #item="record">
                  <div
                    class="items d-flex flex-column flex-md-row justify-content-between p-2">
                    <div class="col-4">{{ record.title }}</div>
                    <div class="col-4">
                      <span
                        class="tag"
                        v-for="keyword in record.keywords"
                        :key="keyword"
                        >{{ keyword }}</span
                      >
                    </div>
                    <div class="col-2">
                      {{
                        new Date(record.createdAt).toLocaleDateString('pt-BR')
                      }}
                    </div>
                    <div class="col-2"></div>
                  </div>
                </template>
              </PaginatedList>
            </div>
            <div v-if="activeTab === 'financial'" class="financial-records">
              <PaginatedList
                :api-path="`patients/${patient.id}/financial-records`"
                class="mt-3"
                :key="financeListKey">
                <template #empty>
                  <div class="blankslate">
                    <p>
                      Não há lançamentos financeiros relacionados a consultas.
                    </p>
                    <router-link
                      class="btn btn-primary"
                      :to="{
                        path: '/appointments/create/2',
                        query: {
                          patientId: patient.id,
                          backTo: $route.fullPath,
                        },
                      }"
                      >Nova consulta</router-link
                    >
                  </div>
                </template>
                <template #header>
                  <div
                    class="header d-none d-md-flex flex-column flex-md-row justify-content-between p-2">
                    <div class="col-4">Descrição</div>
                    <div class="col-2">Vencimento</div>
                    <div class="col-2">Valor</div>
                    <div class="col-2">Status</div>
                    <div class="col-2"></div>
                  </div>
                </template>
                <template #item="record">
                  <div
                    class="d-flex flex-column flex-md-row justify-content-between p-2">
                    <div class="col-md-3 mb-2">{{ record.description }}</div>
                    <div class="col-md-3 mb-2">
                      <i class="bi bi-calendar-event"></i>
                      {{
                        localDate(record.dueDate).toLocaleDateString('pt-BR', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        })
                      }}
                    </div>
                    <div
                      class="col-md-2 d-none d-md-block"
                      :class="record.type">
                      {{ formatCurrency(record.value) }}
                    </div>
                    <div class="col-md-2 d-none d-md-block">
                      <span
                        class="badge badge-height"
                        :class="
                          getFinancialStatusClass(getFinancialStatus(record))
                        "
                        >{{ $t(getFinancialStatus(record)) }}</span
                      >
                    </div>
                    <div class="d-flex flex-row d-md-none">
                      <div class="me-2" :class="record.type">
                        {{ formatCurrency(record.value) }}
                      </div>
                      <div>
                        <span
                          class="badge badge-height"
                          :class="
                            getFinancialStatusClass(getFinancialStatus(record))
                          "
                          >{{ $t(getFinancialStatus(record)) }}</span
                        >
                      </div>
                    </div>
                    <div class="col-md-2">
                      <FinanceActions
                        :finance="record"
                        @financeUpdated="updateFinanceList()" />
                    </div>
                  </div>
                </template>
              </PaginatedList>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.patient-card {
  flex-grow: 1;

  .patient-name {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 30px;
  }
}

.patient-view {
  display: flex;
  flex-flow: row;

  @media screen and (max-width: 800px) {
    flex-flow: column;
  }

  .p-tabview-nav li:first-child {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }

  .patient-info {
    min-width: 300px;
    max-width: 350px;
    padding: 0 30px;

    @media screen and (max-width: 800px) {
      display: none;
    }

    .info {
      margin: 10px 0;
      color: #6c6c6c;
    }
  }

  .tag {
    background: var(--contrast);
    color: #fff;
    border: 1px solid var(--contrast-dark);
    padding: 3px 8px;
    border-radius: 5px;
    margin-right: 5px;
  }

  .item {
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0;
  }

  .header {
    background-color: #efefef;
    border: 1px solid #dfdfdf;

    div {
      font-weight: bold;
      color: #5f5f5f;
    }
  }

  .appointments {
    margin-top: 10px;
    .appointment {
      border-left: 2px solid var(--primary);
      border-bottom: 1px solid #dfdfdf;

      div {
        line-height: 50px;

        * {
          line-height: 25px;
        }

        .badge-height {
          height: 25px;
          line-height: 15px;
        }
      }
    }
  }

  .patient-page {
    flex-grow: 1;
  }

  .financial-records {
    .income {
      color: #2e8b57;
    }

    .outcome {
      color: #e6362a;
    }
  }
}
</style>
