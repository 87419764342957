<script setup>
import { nanoid } from 'nanoid';

import PaginatedList from '../../components/PaginatedList.vue';
import FinanceActions from '../../components/finance/FinanceActions.vue';
</script>

<template>
  <div class="card overdue-finances-container">
    <div class="card-body">
      <h4 class="ms-2 mt-2">Lançamentos Financeiros Atrasados</h4>
      <PaginatedList
        apiPath="/finances/overdue"
        class="mt-4"
        :key="overdueFinancesKey">
        <template #header>
          <div
            class="flex-row justify-content-between header p-2 d-none d-sm-none d-md-flex">
            <div class="col-3 fs-6">Descrição</div>
            <div class="col-3 fs-6">Vencimento</div>
            <div class="col-2 fs-6">Status</div>
            <div class="col-2 fs-6">Valor</div>
            <div class="col-2 fs-6 flex-fill"></div>
          </div>
        </template>
        <template #item="finance">
          <div
            class="d-md-flex flex-row justify-content-between p-3 finance"
            :class="{
              income: finance.type === 'income',
              outcome: finance.type === 'outcome',
            }">
            <div class="col-md-3 mb-2 fs-6 ml-2">
              {{ finance.description }}
            </div>
            <div class="col-md-3 fs-6">
              <i class="bi bi-calendar-event"></i>
              {{ formatDate(finance.dueDate) }}
            </div>
            <div class="col-md-2 fs-6 d-none d-md-block">
              <span class="badge badge-height" :class="statusClass(finance)">
                {{
                  finance.paidAt
                    ? 'Pago'
                    : new Date(finance.dueDate) < new Date()
                    ? 'Atrasado'
                    : 'Em aberto'
                }}
              </span>
            </div>
            <div class="col-md-2 fs-6 d-none d-md-block finance-type">
              {{ formatCurrency(finance.value) }}
            </div>

            <div class="d-block d-md-none mt-2 mb-3">
              <span class="finance-type me-3">
                {{ formatCurrency(finance.value) }}
              </span>
              <span class="badge badge-height" :class="statusClass(finance)">
                {{
                  finance.paidAt
                    ? 'Pago'
                    : new Date(finance.dueDate) < new Date()
                    ? 'Atrasado'
                    : 'Em aberto'
                }}
              </span>
            </div>

            <FinanceActions :finance="finance" @financeUpdated="updateList()" />
          </div>
        </template>
      </PaginatedList>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overdueFinancesKey: nanoid(),
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const localDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60000,
      );
      return localDate.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'short',
      });
    },

    formatCurrency(valueInCents, abs = true) {
      const value = abs ? Math.abs(valueInCents / 100) : valueInCents / 100;
      var formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return formater.format(value);
    },
    statusClass(financialReord) {
      if (financialReord.paidAt) {
        return 'bg-success';
      } else {
        if (new Date(financialReord.dueDate) < new Date()) {
          return 'bg-danger';
        }
        return 'bg-warning';
      }
    },
    updateList() {
      this.overdueFinancesKey = nanoid();
    },
  },
};
</script>

<style lang="scss">
.overdue-finances-container {
  .header {
    background-color: #efefef;
    border: 1px solid #dfdfdf;

    span {
      font-weight: bold;
      color: #5f5f5f;
    }

    @media screen and (max-width: 768px) {
      .header-col {
        width: 25vw;

        span {
          font-size: 15px;
        }
      }
    }
  }

  .finance {
    border-right: 1px solid #dfdfdf;

    &:last-child {
      border-bottom: 1px solid #dfdfdf;
    }

    &.income {
      border-left: 5px solid #2e8b57;

      .finance-type {
        color: #2e8b57;
      }
    }

    &.outcome {
      border-left: 5px solid #f44336;

      .finance-type {
        color: #e6362a;
      }
    }
  }
}
</style>
