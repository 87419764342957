<script setup>
import AppointmentActions from './AppointmentActions.vue';
</script>

<template>
  <div class="appointment-card" :class="appointmentStatusClass()">
    <div class="d-flex flex-row">
      <div class="title flex-grow-1">
        {{ appointment.patientName }}
      </div>
      <div class="time">
        {{
          new Date(appointment.startAt).toLocaleDateString('pt-BR', {
            weekday: 'short',
          })
        }}
        {{
          new Date(appointment.startAt).toLocaleDateString('pt-BR', {
            day: 'numeric',
            month: 'short',
          })
        }}
        {{
          new Date(appointment.startAt).toLocaleTimeString('pt-BR', {
            hour: 'numeric',
            minute: 'numeric',
          })
        }}
        -
        {{
          new Date(appointment.endAt).toLocaleTimeString('pt-BR', {
            hour: 'numeric',
            minute: 'numeric',
          })
        }}
      </div>
    </div>

    <div class="d-flex flex-row">
      <div
        class="contact flex-grow-1"
        v-if="appointment.patientPhone || appointment.patientEmail">
        <div v-if="appointment.patientPhone">
          <i class="bi bi-telephone"></i> {{ appointment.patientPhone }}
        </div>
        <div v-if="appointment.patientEmail">
          <i class="bi bi-envelope-at"></i> {{ appointment.patientEmail }}
        </div>
      </div>
      <div class="align-self-end">
        <AppointmentActions
          :appointment="appointment"
          clean-button="true"
          @setComplete="triggerForceUpdate"
          @setCanceled="triggerForceUpdate"
          @setPending="triggerForceUpdate"
          @rescheduled="triggerForceUpdate" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['appointment'],
  methods: {
    appointmentStatusClass() {
      if (
        this.appointment?.status == 'pending' &&
        new Date(this.appointment.startAt).getTime() < new Date().getTime()
      ) {
        return 'delayed';
      }
    },
    triggerForceUpdate() {
      this.$emit('appointment-updated');
    },
  },
};
</script>

<style lang="scss">
.appointment-card {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  border-left: 5px solid var(--primary);

  &.delayed {
    border-left: 5px solid #e32b1e;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-darkest);
  }

  .time {
    font-size: 15px;
    font-weight: bold;
    color: var(--primary-darkest);

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .contact {
    margin-top: 10px;
    font-size: 14px;
  }
}
</style>
