<script setup>
import { vMaska } from 'maska';
import { ref } from 'vue';

import apiClient from '../../util/api-client';
import getCurrentUser from '../../util/current-user';
</script>

<script>
const items = ref([
  'Psicologia',
  'Nutrição',
  'Médicina',
  'Terapia ocupacional',
  'Fisioterapia',
  'Outro',
]);

export default {
  async mounted() {
    this.userId = getCurrentUser().userId;
    const result = await apiClient.users.getUser(this.userId);
    if (result.ok) {
      this.user = result.data;
    }
  },
  data() {
    return {
      userId: null,
      errors: {},
      user: {
        name: '',
        email: null,
        phone: null,
        specialty: '',
        password: '',
      },
      currentPassword: '',
    };
  },
  methods: {
    getClass(field) {
      if (this.errors[field]) return 'p-invalid';
      return '';
    },
    getTitle() {
      if (this.userId) return 'Editar Usuario';
    },
    async saveUser(event) {
      event.preventDefault();

      const errors = this.validate();
      this.errors = errors;

      if (Object.keys(this.errors).length === 0) {
        const response = await apiClient.users.updateUser(
          this.user,
          this.currentPassword,
        );
        if (!response.ok) {
          this.errors = response.errors;
        } else {
          this.$toast({
            severity: 'success',
            title: 'Sucesso',
            message: 'Dados salvo com sucesso',
            life: 3000,
          });
          this.currentPassword = '';
        }
      }
    },
    validate() {
      const errors = {};

      if (!this.user.name) errors.name = 'Nome é obrigatório';
      if (!this.user.email) errors.email = 'Email é obrigatório';
      if (!this.user.phone) errors.phone = 'Telefone é obrigatório';
      if (!this.user.confirmPassword && this.user.password)
        errors.confirmPassword = 'Confirmação de senha é obrigatória';

      const passwordRegex =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
      if (this.user.password && !passwordRegex.test(this.user.password))
        errors.password = 'Senha fraca, informe uma senha mais forte';

      if (this.user.password !== this.user.confirmPassword)
        errors.confirmPassword = 'Confirmação de senha não é igual a senha';
      return errors;
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="row justify-content-center rounded bg-white shadow-sm">
      <div class="col-xl col-md-9 pt-0">
        <div class="card mt-5 border-0 bg-white">
          <div class="card-body pt-0">
            <h3 class="card-title text-muted mb-5 mt-n5">
              {{ getTitle() }}
            </h3>
            <form @submit.prevent="saveUser">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="user.name"
                  :class="getClass('name')"
                  placeholder="Nome" />
                <label for="name">Nome</label>
                <small class="form-text text-danger" v-if="errors.name">{{
                  $t(errors.name)
                }}</small>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="user.email"
                  :class="getClass('email')"
                  placeholder="Email" />
                <label for="email">Email</label>
                <small class="form-text text-danger" v-if="errors.email">{{
                  $t(errors.email)
                }}</small>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="phone"
                  class="form-control"
                  id="phone"
                  v-model="user.phone"
                  :class="getClass('phone')"
                  placeholder="Telefone"
                  v-maska
                  data-maska="(##) #########" />
                <label for="phone">Telefone</label>
                <small class="form-text text-danger" v-if="errors.phone">{{
                  $t(errors.phone)
                }}</small>
              </div>
              <div class="form-floating mb-3">
                <select
                  v-model="user.specialty"
                  class="form-select"
                  id="specialty"
                  aria-label="Especialidade">
                  <option
                    v-for="specialty in items"
                    :key="specialty"
                    :value="specialty"
                    :class="getClass('specialty')">
                    {{ specialty }}
                  </option>
                </select>
                <label for="specialty">Especialidade</label>
                <small class="form-text text-danger" v-if="errors.phone">{{
                  $t(errors.phone)
                }}</small>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="user.password"
                  :class="getClass('password')"
                  placeholder="Nova senha" />
                <label for="password">Nova senha</label>
                <small class="form-text text-danger" v-if="errors.password">{{
                  $t(errors.password)
                }}</small>
              </div>
              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  id="password-confirm"
                  v-model="user.confirmPassword"
                  :class="getClass('confirmPassword')"
                  placeholder="Confirmar nova senha" />
                <label for="password-confirm">Confirmar nova senha</label>
                <small
                  class="form-text text-danger"
                  v-if="errors.confirmPassword"
                  >{{ $t(errors.confirmPassword) }}</small
                >
              </div>
              <div class="form-floating mb-3">
                <input
                  type="password"
                  class="form-control"
                  id="currentPassword"
                  v-model="currentPassword"
                  :class="getClass('currentPassword')"
                  placeholder="Senha atual" />
                <label for="currentPassword">Senha atual</label>
                <small
                  class="form-text text-danger"
                  v-if="errors.currentPassword"
                  >{{ $t(errors.currentPassword) }}</small
                >
              </div>
              <div class="d-flex gap-2 w-1">
                <button type="submit" class="btn btn-primary">Salvar</button>
                <button
                  type="button"
                  class="btn btn-link"
                  v-on:click="$router.push('/')">
                  Voltar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
