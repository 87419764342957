<script setup>
import { Modal } from 'bootstrap';

import FinanceForm from './FinanceForm.vue';
</script>
<template>
  <div>
    <a class="dropdown-item" href="#" v-on:click.prevent="openForm()">
      <slot></slot>
    </a>

    <Teleport to="body">
      <div
        id="financeModal"
        class="modal"
        ref="financeModal"
        tabindex="-1"
        aria-hidden="true">
        <div class="modal-dialog modal-fullscreen-sm-down">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {{
                  type == 'income' && financeId == null
                    ? 'Adicionar Receita'
                    : type == 'outcome' && financeId == null
                    ? 'Adicionar Despesa'
                    : 'Editar Lançamento'
                }}
              </h1>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <FinanceForm
                :options="financeFormOptions"
                @financeUpdated="onModalClose"
                @financeCreated="onModalClose" />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
export default {
  props: ['financeId', 'type'],
  mounted() {
    const el = this.$refs.financeModal;
    this.financeModal = new Modal(el);
  },
  data() {
    return {
      financeModal: null,
      financeFormOptions: {
        financeId: null,
        type: 'income',
      },
    };
  },
  methods: {
    openForm() {
      this.financeFormOptions = {
        financeId: this.financeId,
        type: this.type,
      };
      this.financeModal.show();
    },
    closeForm() {
      this.financeModal.hide();
    },
    onModalClose() {
      this.$emit('finance-updated', this.finance);
      this.closeForm();
    },
  },
};
</script>
