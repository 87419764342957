<script setup>
import CurrencyInput from '../../components/CurrencyInput.vue';
import apiClient from '../../util/api-client';
</script>

<script>
export default {
  props: ['options'],
  data() {
    return {
      isPaid: false,
      originalPaidAt: null,
      financialRecord: {
        value: null,
        description: null,
        dueDate: null,
        type: 'income',
        paidAt: null,
      },
      errors: {},
    };
  },
  watch: {
    options() {
      this.financialRecord = {
        value: null,
        description: null,
        dueDate: null,
        type: 'income',
        paidAt: null,
      };
      this.initialize();
    },
    isPaid(newValue) {
      if (newValue) {
        let paidAt = new Date();
        if (this.originalPaidAt) {
          paidAt = this.originalPaidAt;
        }
        this.financialRecord.paidAt = `${paidAt.getFullYear()}-${(
          paidAt.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}-${paidAt.getDate().toString().padStart(2, '0')}`;
      } else {
        this.financialRecord.paidAt = null;
      }
    },
  },
  methods: {
    async initialize() {
      if (this.options.financeId) {
        const financialRecordEdit = await apiClient.financial.findById(
          this.options.financeId,
        );
        this.financialRecord = financialRecordEdit.data.financial;
        this.financialRecord.value = Math.abs(this.financialRecord.value / 100);
        const dueDate = new Date(this.financialRecord.dueDate);
        this.financialRecord.dueDate = `${dueDate.getFullYear()}-${(
          dueDate.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}-${dueDate.getDate().toString().padStart(2, '0')}`;

        if (this.financialRecord.paidAt) {
          this.isPaid = true;
          const paidAt = new Date(this.financialRecord.paidAt);
          paidAt.setTime(paidAt.getTime() + paidAt.getTimezoneOffset() * 60000);
          this.originalPaidAt = paidAt;
          this.financialRecord.paidAt = `${paidAt.getFullYear()}-${(
            paidAt.getMonth() + 1
          )
            .toString()
            .padStart(2, '0')}-${paidAt.getDate().toString().padStart(2, '0')}`;
        }
      } else {
        this.financialRecord.type = this.options.type;
      }
    },
    async formAction() {
      this.financialRecord.value = Math.round(this.financialRecord.value * 100);

      if (this.options.financeId) {
        const financeId = this.options.financeId;

        const result = await apiClient.financial.updateFinancialRecord(
          financeId,
          this.financialRecord,
        );
        if (result.ok) {
          this.financialRecord.dueDate = '';
          this.financialRecord.description = '';
          this.financialRecord.value = '';
          this.$emit('finance-updated', this.financialRecord);
          this.$toast({
            severity: 'success',
            title: 'Sucesso',
            message: 'Registro financeiro atualizado com sucesso',
            life: 3000,
          });
        } else {
          this.errors = result.errors;
        }
      } else {
        this.financialRecord.type = this.options.type;
        const result = await apiClient.financial.createFinancialRecord(
          this.financialRecord,
        );
        if (result.ok) {
          this.financialRecord.dueDate = '';
          this.financialRecord.description = '';
          this.financialRecord.value = '';
          this.$emit('finance-created', this.financialRecord);
          this.$toast({
            severity: 'success',
            title: 'Sucesso',
            message:
              this.options.type == 'income'
                ? 'Registro financeiro criado com sucesso'
                : 'Despesa criada com sucesso',
            life: 3000,
          });
        } else {
          this.errors = result.errors;
        }
      }
    },
  },
};
</script>

<template>
  <form class="row g-3" @submit.prevent="formAction">
    <div class="col-12">
      <label for="inputDescription" class="form-label">Descrição</label>
      <input
        type="text"
        v-model="financialRecord.description"
        class="form-control"
        id="inputDescription" />
      <small class="error-message" v-if="errors.description">{{
        $t(errors.description)
      }}</small>
    </div>
    <div class="col-md-6">
      <label for="inputValue" class="form-label">Valor</label>
      <!-- <input
        type="text"
        v-model=""
        class="form-control"
        id="inputValue" /> -->

      <CurrencyInput
        id="price"
        v-model="financialRecord.value"
        class="form-control" />

      <small class="error-message" v-if="errors.value">{{
        $t(errors.value)
      }}</small>
    </div>
    <div class="col-md-6">
      <label for="inputDueDate" class="form-label">Data de vencimento</label>
      <input
        type="date"
        class="form-control"
        id="date"
        v-model="financialRecord.dueDate"
        placeholder="DD/MM/AAAA" />
      <small class="error-message" v-if="errors.dueDate">{{
        $t(errors.dueDate)
      }}</small>
    </div>
    <div class="col-md-12">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="isPaid"
          v-model="isPaid" />
        <label class="form-check-label" for="rescheduleNext"> Pago </label>
      </div>
    </div>
    <div class="col-md-6" v-if="isPaid">
      <label for="inputPaidAt" class="form-label">Data de pagamento</label>
      <input
        type="date"
        class="form-control"
        id="date"
        v-model="financialRecord.paidAt"
        placeholder="DD/MM/AAAA" />
      <small class="error-message" v-if="errors.paidAt">{{
        $t(errors.paidAt)
      }}</small>
    </div>
    <div class="row-md-5"></div>
    <div class="d-flex col-12">
      <button type="submit" class="btn btn-primary">Salvar</button>
    </div>
  </form>
</template>
