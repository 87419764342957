<script setup>
import { nanoid } from 'nanoid';

import MonthlyList from '../../components/MonthlyList.vue';
import FinanceActions from '../../components/finance/FinanceActions.vue';
import FinanceModalButton from '../../components/finance/FinanceModalButton.vue';
import apiClient from '../../util/api-client';
</script>

<script>
export default {
  data() {
    return {
      listKey: nanoid(),
      filter: null,
      finances: [],
      totalItems: null,
      monthlySummary: {
        pending: { income: 0, outcome: 0 },
        paid: { income: 0, outcome: 0 },
      },
    };
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return null;

      const date = new Date(dateString);
      const localDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60000,
      );
      return localDate.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatCurrency(valueInCents, abs = true) {
      const value = abs ? Math.abs(valueInCents / 100) : valueInCents / 100;
      var formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return formater.format(value);
    },

    updateList() {
      this.listKey = nanoid();
    },

    statusClass(financialReord) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (financialReord.paidAt) {
        return 'bg-success';
      } else {
        if (new Date(financialReord.dueDate) < today) {
          return 'bg-danger';
        }
        return 'bg-warning';
      }
    },
    async onMonthSelected(selectedMonth) {
      const result = await apiClient.financial.monthlySummary(selectedMonth);
      this.monthlySummary = result.data;
    },
    incomeOrOutcomeClass(value) {
      if (value >= 0) return 'income';
      return 'outcome';
    },
  },
  computed: {
    incomeSummary() {
      return {
        paid: this.monthlySummary.paid.income,
        pending: this.monthlySummary.pending.income,
        total:
          this.monthlySummary.paid.income + this.monthlySummary.pending.income,
      };
    },
    outcomeSummary() {
      return {
        paid: this.monthlySummary.paid.outcome * -1,
        pending: this.monthlySummary.pending.outcome * -1,
        total:
          (this.monthlySummary.paid.outcome +
            this.monthlySummary.pending.outcome) *
          -1,
      };
    },
  },
};
</script>
<template>
  <div class="card mb-4 finances-container">
    <div class="card-body">
      <MonthlyList
        api-path="/finances"
        class="finances"
        :key="listKey"
        @monthSelected="onMonthSelected">
        <template #empty>
          <div class="blankslate">
            <p>
              Sem lançamentos no período selecionado. Registre agora mesmo para
              manter o controle das suas finanças!
            </p>
          </div>
        </template>
        <template #header>
          <div class="d-md-flex flex-row mt-4">
            <div class="col-md-4 summary-box">
              <div class="pe-4 ps-4 pb-3 pt-3">
                <div class="row line">
                  <div class="col-6 title">Receitas</div>
                  <div class="col-6 income text-end title">
                    {{ formatCurrency(incomeSummary.total) }}
                  </div>
                </div>
                <div class="row line">
                  <div class="col-6">Recebido</div>
                  <div class="col-6 income text-end">
                    {{ formatCurrency(incomeSummary.paid) }}
                  </div>
                </div>
                <div class="row line">
                  <div class="col-6">A receber</div>
                  <div class="col-6 income text-end">
                    {{ formatCurrency(incomeSummary.pending) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 summary-box">
              <div class="pe-4 ps-4 pb-3 pt-3">
                <div class="row line">
                  <div class="col-6 title">Despesas</div>
                  <div class="col-6 outcome text-end title">
                    {{ formatCurrency(outcomeSummary.total) }}
                  </div>
                </div>
                <div class="row line">
                  <div class="col-6">Pago</div>
                  <div class="col-6 outcome text-end">
                    {{ formatCurrency(outcomeSummary.paid) }}
                  </div>
                </div>
                <div class="row line">
                  <div class="col-6">A pagar</div>
                  <div class="col-6 outcome text-end">
                    {{ formatCurrency(outcomeSummary.pending) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 summary-box">
              <div class="pe-4 ps-4 pb-3 pt-3">
                <div class="row line">
                  <div class="col-6 title">Saldo Final</div>
                  <div
                    class="col-6 text-end title"
                    :class="
                      incomeOrOutcomeClass(
                        incomeSummary.total - outcomeSummary.total,
                      )
                    ">
                    {{
                      formatCurrency(
                        incomeSummary.total - outcomeSummary.total,
                        false,
                      )
                    }}
                  </div>
                </div>
                <div class="row line">
                  <div class="col-6">Saldo Atual</div>
                  <div
                    class="col-6 text-end"
                    :class="
                      incomeOrOutcomeClass(
                        incomeSummary.paid - outcomeSummary.paid,
                      )
                    ">
                    {{
                      formatCurrency(
                        incomeSummary.paid - outcomeSummary.paid,
                        false,
                      )
                    }}
                  </div>
                </div>
                <!-- <div class="row line">
                  <div class="col-6">Saldo Final</div>
                  <div
                    class="col-6 text-end"
                    :class="
                      incomeOrOutcomeClass(
                        incomeSummary.total - outcomeSummary.total,
                      )
                    ">
                    {{
                      formatCurrency(
                        incomeSummary.total - outcomeSummary.total,
                        false,
                      )
                    }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div
            class="flex-row justify-content-between header p-2 d-none d-sm-none d-md-flex">
            <div class="col-2 fs-6">Data</div>
            <div class="col-4 fs-6">Descrição</div>
            <div class="col-2 fs-6">Status</div>
            <div class="col-2 fs-6">Valor</div>
            <div class="col-2 fs-6 flex-fill"></div>
          </div>

          <div class="header p-2 d-block d-md-none">Lançamentos</div>
        </template>

        <template #actions>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <FinanceModalButton
              type="income"
              class="btn btn-outline-success"
              @financeUpdated="updateList()">
              Nova Receita
            </FinanceModalButton>
            <FinanceModalButton
              type="outcome"
              class="btn btn-outline-danger"
              @financeUpdated="updateList()">
              Nova Despesa
            </FinanceModalButton>
          </div>
        </template>

        <template #item="finance">
          <div
            class="d-md-flex flex-row justify-content-between p-3 finance align-items-center"
            :class="{
              income: finance.type === 'income',
              outcome: finance.type === 'outcome',
            }">
            <div class="col-md-2 fs-6">
              <div v-if="!finance.paidAt">
                {{ formatDate(finance.dueDate) }}
              </div>
              <div>
                {{ formatDate(finance.paidAt) }}
              </div>
            </div>
            <div class="col-md-4 fs-6">
              {{ finance.description }}
            </div>
            <div class="col-md-2 fs-6 d-none d-md-block">
              <span class="badge badge-height" :class="statusClass(finance)">
                {{
                  finance.paidAt
                    ? 'Pago'
                    : new Date(finance.dueDate) < new Date()
                    ? 'Atrasado'
                    : 'Em aberto'
                }}
              </span>
            </div>
            <div class="col-md-2 fs-6 d-none d-md-block finance-type">
              {{ formatCurrency(finance.value) }}
            </div>

            <div class="d-block d-md-none">
              <span class="finance-type me-3">
                {{ formatCurrency(finance.value) }}
              </span>
              <span class="badge badge-height" :class="statusClass(finance)">
                {{
                  finance.paidAt
                    ? 'Pago'
                    : new Date(finance.dueDate) < new Date()
                    ? 'Atrasado'
                    : 'Em aberto'
                }}
              </span>
            </div>

            <FinanceActions :finance="finance" @financeUpdated="updateList()" />
          </div>
        </template>
      </MonthlyList>
    </div>
  </div>
</template>

<style lang="scss">
.finances-container {
  .btn-today::first-letter {
    text-transform: uppercase;
  }

  .finances {
    .summary-box {
      color: #929292;
      font-size: 15px;
      background-color: #fafafa;
      border-top: 1px solid #dedede;
      border-left: 1px solid #dedede;

      @media screen and (max-width: 768px) {
        border-right: 1px solid #dedede;
      }

      &:last-of-type {
        border-right: 1px solid #dedede;
      }

      .line {
        border-bottom: 1px dashed #dedede;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #787878;

        &.income {
          color: #2e8b57;
        }

        &.outcome {
          color: #e6362a;
        }
      }

      .income {
        border-left: none;
        color: #69a683;
      }

      .outcome {
        border-left: none;
        color: #f2948d;
      }
    }

    .income {
      border-left: 5px solid #2e8b57;

      .finance-type {
        color: #2e8b57;
      }
    }

    .outcome {
      border-left: 5px solid #f44336;

      .finance-type {
        color: #e6362a;
      }
    }

    .header {
      background-color: #efefef;
      border: 1px solid #dfdfdf;

      span {
        font-weight: bold;
        color: #5f5f5f;
      }

      @media screen and (max-width: 768px) {
        .header-col {
          width: 25vw;

          span {
            font-size: 15px;
          }
        }
      }
    }

    .finance {
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;

      &:hover {
        background-color: #f6f6f6;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .due-date-paid {
        text-decoration: line-through;
        font-size: 14px;
        color: #636363;
      }

      .finance-actions-container {
        height: 100%;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
          justify-content: left;

          .action-button {
            width: 30px;
            height: 30px;
            padding: 0;

            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
