<script setup>
import apiClient from '../../util/api-client';
</script>

<template>
  <div class="record-view card">
    <div class="card-body page">
      <div class="d-flex flex-row">
        <h3 class="flex-grow-1">{{ record.title }}</h3>
        <div>
          <button
            class="btn btn-outline-danger btn-sm"
            v-on:click="deleteRecord">
            <i class="bi bi-trash"></i> Excluir
          </button>
        </div>
      </div>
      <div class="tags">
        <h5>Palavras-chave</h5>
        <span
          class="tag"
          v-for="tag in record.keywords"
          :key="`tag-record-${tag}`">
          {{ tag }}
        </span>
      </div>
      <h5>Documento</h5>
      <div class="document">
        <div class="ql-container">
          <div class="ql-editor" v-html="record.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      record: {},
    };
  },
  async mounted() {
    const patientId = this.$route.params.patientId;
    const recordId = this.$route.params.recordId;
    const result = await apiClient.patients.findPatientRecord(
      patientId,
      recordId,
    );
    if (result.ok) {
      this.record = result.patientRecord;
    }
  },
  methods: {
    deleteRecord() {
      const patientId = this.$route.params.patientId;
      const recordId = this.$route.params.recordId;

      this.$confirm({
        message: `Deseja realmente excluir o documento ${this.record.title}?`,
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-danger',
        acceptLabel: 'Excluir',
        rejectLabel: 'Cancelar',
        onAccept: async () => {
          const result = await apiClient.patients.deletePatientRecord(
            patientId,
            recordId,
          );
          if (result.ok) {
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Documento excluido com sucesso!',
              life: 3000,
            });
            this.$router.back();
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.record-view {
  .page {
    display: flex;
    flex-flow: column;
  }
  .document {
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    flex-grow: 1;
  }
  .tags {
    margin: 30px 0;
    .tag {
      background: var(--contrast);
      color: #fff;
      border: 1px solid var(--contrast-dark);
      padding: 3px 8px;
      border-radius: 5px;
      margin-right: 5px;
    }
  }
}
</style>
