<script setup>
import apiClient from '../../util/api-client';
</script>

<template>
  <div class="request-recover">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-4">Recuperar Conta</h5>
        <div v-if="success">
          Enviamos um link de recuperação de senha para o e-mail
          <b>{{ email }}</b
          >.
        </div>
        <form @submit.prevent="requestRecover" v-if="!success">
          <div class="mb-3">
            <label class="form-label" for="name">Email</label>
            <div class="input-group">
              <span class="input-group-text">
                <i class="bi bi-at"></i>
              </span>
              <input
                id="email"
                type="text"
                v-model="email"
                class="form-control"
                v-bind:class="getClass('email')" />
            </div>
            <small class="error-message" v-if="errors.email">{{
              $t(errors.email)
            }}</small>
          </div>
          <button type="submit" class="btn btn-primary mt-3">
            Enviar Código
          </button>
          <router-link class="btn btn-link mt-3" to="/login">Login</router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      errors: {},
      success: false,
    };
  },
  methods: {
    async requestRecover() {
      const result = await apiClient.account.requestRecover(this.email);
      if (result.ok) {
        this.success = true;
      } else {
        this.errors.email = result.errors;
      }
    },
    getClass(field) {
      if (this.errors[field]) return 'p-invalid';
      return '';
    },
  },
};
</script>

<style lang="scss">
.request-recover {
  display: flex;
  align-items: center;
  .card {
    width: 100%;
    max-width: 500px;
  }
}
</style>
