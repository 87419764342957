<script setup>
import Loader from '../../components/Loader.vue';
import apiClient from '../../util/api-client';
</script>

<script>
function conflictTree(events) {
  const _events = [...events];

  _events.sort((a, b) => b.duration - a.duration);

  const addedIds = {};
  const rootNodes = [];

  for (let i in _events) {
    const eventA = _events[i];
    if (!addedIds[eventA.id]) {
      rootNodes.push(eventA);
      addedIds[eventA.id] = true;

      const conflicts = [];

      for (let j in _events) {
        const eventB = _events[j];

        if (eventA.id === eventB.id) continue;

        const bStartsDuringA =
          eventA.startAt <= eventB.startAt && eventA.endAt >= eventB.startAt;
        const bEndsDuringA =
          eventA.startAt <= eventB.endAt && eventA.endAt >= eventB.endAt;
        const bIsOverA =
          eventB.startAt <= eventA.startAt && eventB.endAt >= eventA.endAt;

        if (bStartsDuringA || bEndsDuringA || bIsOverA) {
          conflicts.push(eventB);
          addedIds[eventB.id] = true;
        }
      }

      const _conflicts = conflictTree(conflicts);
      eventA.conflicts = _conflicts;
    }
  }

  return rootNodes;
}

function parseEvents(events) {
  return events.map(e => {
    const startAt = new Date(e.startAt);
    const endAt = new Date(e.endAt);

    return {
      id: e.id,
      startAt: startAt,
      endAt: endAt,
      description: e.patientName,
      duration: (endAt.getTime() - startAt.getTime()) / 60000,
      status: e.status,
      patient: {
        id: e.patientId,
        name: e.patientName,
        phone: e.patientPhone,
        email: e.patientEmail,
      },
    };
  });
}

/**
 * encontra maior branch
 */

function findLongestBranch(nodes, currentBranch = []) {
  const childBranches = [];
  for (let i in nodes) {
    const node = nodes[i];
    const branch = [node.id, ...currentBranch];
    if (node.conflicts && node.conflicts.length > 0) {
      const result = findLongestBranch(node.conflicts, branch);
      if (result) childBranches.push(result);
    } else {
      childBranches.push(branch);
    }
  }

  childBranches.sort((a, b) => b.length - a.length);
  return childBranches[0];
}

/**
 * Maior branch onde o evento se encontra
 */
function findLongestBranchIncludingEvent(eventId, nodes, currentBranch = []) {
  for (let i in nodes) {
    const node = nodes[i];
    const branch = [...currentBranch, node.id];

    if (node.id === eventId) {
      if (node.conflicts.length > 0) {
        const longestBranch = findLongestBranch(node.conflicts);
        return branch.concat(longestBranch);
      }

      return branch;
    } else {
      if (node.conflicts.length > 0) {
        const result = findLongestBranchIncludingEvent(
          eventId,
          node.conflicts,
          branch,
        );
        if (result) return result;
      }
    }
  }
}

export default {
  props: ['eventBus'],
  expose: ['firstDay', 'lastDay'],
  data() {
    return {
      loading: false,
      firstDay: null,
      lastDay: null,
      week: [],
      events: {},
      rawEvents: [],
      conflicts: [],
    };
  },
  beforeMount() {
    const now = new Date();
    const firstDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay(),
    );
    const lastDay = new Date(firstDay.getTime());
    lastDay.setDate(firstDay.getDate() + 6);

    this.firstDay = firstDay;
    this.lastDay = lastDay;
  },
  mounted() {
    this.loadData();

    this.eventBus.on('force-update', () => {
      this.loadData();
    });
  },
  methods: {
    async loadData() {
      this.loading = true;
      const day = new Date(this.firstDay.getTime());

      const response = await apiClient.appointments.listAppointments(
        this.firstDay,
        this.lastDay,
      );

      if (response.ok) {
        const eventList = parseEvents(response.data);

        this.conflicts = conflictTree(eventList);

        this.rawEvents = eventList;
        const events = eventList
          .map(e => ({
            key: new Date(e.startAt).getDay(),
            event: e,
          }))
          .reduce((acc, e) => {
            const events = acc[e.key] || [];
            events.push(e.event);
            acc[e.key] = events;
            return acc;
          }, {});

        this.events = events;
      }
      this.week = [];
      while (day.getTime() <= this.lastDay.getTime()) {
        const weekday = {
          date: new Date(day.getTime()),
          events: this.events[day.getDay()],
        };

        this.week.push(weekday);
        day.setDate(day.getDate() + 1);
      }

      this.loading = false;
      this.setScroll();
    },
    getTop(event) {
      const containerHeight = 1200;

      const startAt = new Date(event.startAt);
      const eventOffset = startAt.getHours() * 60 + startAt.getMinutes();
      const eventTop = (containerHeight / 1440) * eventOffset;

      return `${eventTop}px`;
    },

    getHeight(event) {
      const containerHeight = 1200;

      const startAt = new Date(event.startAt);
      const endAt = new Date(event.endAt);

      const diff = (endAt.getTime() - startAt.getTime()) / 60000;

      const eventHeight = (containerHeight / 1440) * diff;
      return `${eventHeight}px`;
    },

    getWidth(event) {
      const branch = findLongestBranchIncludingEvent(event.id, this.conflicts);
      return `calc(100%/${branch.length})`;
    },

    getLeft(event) {
      const branch = findLongestBranchIncludingEvent(event.id, this.conflicts);
      const position = branch.indexOf(event.id);
      return `calc((100%/${branch.length}) * ${position})`;
    },

    setScroll() {
      const lower =
        this.rawEvents
          .map(e => {
            const startAt = new Date(e.startAt);
            return startAt.getHours() * 60 + startAt.getMinutes();
          })
          .sort((a, b) => a - b)?.[0] || 0;

      const containerHeight = 1200;
      const scroll = (containerHeight / 1440) * lower;

      this.$nextTick(() => {
        if (this.$refs.events) this.$refs.events.scroll({ top: scroll });
      });
    },

    eventClicked(event) {
      const _event = Object.assign({}, event);
      this.$emit('eventClicked', _event);
    },

    nextWeek() {
      const firstDay = new Date(this.firstDay.getTime());
      const lastDay = new Date(this.lastDay.getTime());
      firstDay.setDate(firstDay.getDate() + 7);
      lastDay.setDate(lastDay.getDate() + 7);

      this.firstDay = firstDay;
      this.lastDay = lastDay;

      this.loadData();
      this.$emit('weekChanged', { firstDay: firstDay, lastDay: lastDay });
    },
    previousWeek() {
      const firstDay = new Date(this.firstDay.getTime());
      const lastDay = new Date(this.lastDay.getTime());
      firstDay.setDate(firstDay.getDate() - 7);
      lastDay.setDate(lastDay.getDate() - 7);

      this.firstDay = firstDay;
      this.lastDay = lastDay;

      this.loadData();
      this.$emit('weekChanged', { firstDay: firstDay, lastDay: lastDay });
    },
    openToday() {
      const now = new Date();
      const firstDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - now.getDay(),
      );
      const lastDay = new Date(firstDay.getTime());
      lastDay.setDate(firstDay.getDate() + 6);

      this.firstDay = firstDay;
      this.lastDay = lastDay;
      this.loadData();
      this.$emit('monthChanged', {
        month: this.currentMonth,
        year: this.currentYear,
      });
    },
    todayClass(date) {
      const today = new Date();
      if (today.toDateString() === date.toDateString()) return 'today';
    },
  },
};
</script>

<template>
  <Loader v-if="loading" />
  <div v-if="!loading" class="weekly-schedule-container">
    <div class="d-flex justify-content-end">
      <div class="btn-group calendar-nav mb-3 align-self-end" role="group">
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          v-on:click="previousWeek">
          <i class="bi bi-chevron-left"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm btn-today"
          v-on:click="openToday">
          Hoje
        </button>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          v-on:click="nextWeek">
          <i class="bi bi-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="weekly-schedule" ref="events">
      <div class="line header">
        <div class="column">
          <div class="cel header column-header">Hora</div>
        </div>
        <div
          class="column"
          v-for="weekday in week"
          :key="weekday.date.getTime()">
          <div class="cel header column-header">
            <span :class="todayClass(weekday.date)">{{
              weekday.date.toLocaleDateString('pt-BR', {
                weekday: 'short',
              })
            }}</span>
            <span class="day-label"
              >{{
                weekday.date.toLocaleDateString('pt-BR', {
                  day: 'numeric',
                  month: 'short',
                })
              }}
            </span>
          </div>
        </div>
      </div>

      <div class="line events">
        <div class="column">
          <div class="cel header" v-for="hour in Array(24).keys()" :key="hour">
            {{ hour }}:00
          </div>
        </div>
        <div
          class="column"
          v-for="weekday in week"
          :key="weekday.date.getTime()">
          <div class="daily-events">
            <div
              class="event"
              v-for="event in weekday.events"
              :key="event.id"
              :style="`top: ${getTop(event)}; height: ${getHeight(
                event,
              )}; width: ${getWidth(event)}; left: ${getLeft(event)}`"
              v-on:click="eventClicked(event)">
              {{ event.description }}
            </div>
          </div>
          <div class="cel" v-for="hour in Array(24).keys()" :key="hour"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.weekly-schedule-container {
  $eventHeight: 50px;
  $borderColor: #dfdfdf;

  .calendar-nav {
    height: 35px;

    .btn-today {
      min-width: 100px;
    }
  }

  .week-title {
    color: #3d78bc;
    font-weight: 100;
    font-size: 23px;
    padding: 8px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .weekly-schedule {
    display: flex;
    flex-flow: column;
    border-left: 1px solid $borderColor;
    border-top: 1px solid $borderColor;

    overflow-y: scroll;
    max-height: calc(100vh - 250px);
    scrollbar-gutter: stable;

    .column {
      display: flex;
      flex-flow: column;
      width: calc(100% / 8);
      border-right: 1px solid $borderColor;
      height: 100%;

      .column-header {
        line-height: 35px;
        height: 55px;
        .day-label {
          display: block;
          height: 13px;
          font-size: 13px;
          line-height: 13px;
        }
      }
    }

    .line {
      display: flex;
      flex-flow: row;
      width: 100%;

      &.header {
        position: sticky;
        top: 0;
        z-index: 1000;
      }
    }

    .cel {
      height: 50px;
      width: 100%;
      border-bottom: 1px solid $borderColor;
      text-align: center;
      line-height: 50px;

      &.header {
        text-transform: capitalize;
        color: #868686;
        background-color: #fafafa;

        .today {
          background-color: rgb(106, 151, 185);
          color: #fff;
          padding: 5px 10px;
          border-radius: 10px;
          line-height: 20px;
        }
      }
    }

    .daily-events {
      .event {
        position: absolute;
        width: 100%;
        overflow: hidden;
        padding: 0 5px;
        white-space: nowrap;
        text-align: left;
        border: 1px solid var(--primary);
        background-color: var(--primary-light);
        color: var(--primary-dark);
        border-radius: 3px;
        // min-height: 25px;
        z-index: 100;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
