import clinicClient from 'clinic-client';

import sessionStorage from '../util/storage/session-storage';

const basePath = process.env.CLINDY_API || 'http://localhost:8080';

const client = clinicClient(basePath, () => sessionStorage.getToken());

client.addResponseHandler(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status == 403) {
      sessionStorage.clearToken();
      window.location.reload();
    } else {
      throw error;
    }
  },
);

window.api = client;

export default client;
