<script setup>
import * as shared from 'clindy-shared';

import CurrencyInput from '../../components/CurrencyInput.vue';
import apiClient from '../../util/api-client';
import mixpanel from '../../util/mixpanel';
</script>

<script>
export default {
  beforeMount() {
    if (!this.$route.params.step)
      this.$router.push({
        path: '/appointments/create/1',
        query: this.$route.query,
      });

    const patientId = this.$route.query.patientId;
    if (!patientId) {
      this.$router.push('/appointments/create/1');
    } else if (this.$route.params.step === '3')
      this.$router.push({
        path: '/appointments/create/2',
        query: this.$route.query,
      });
  },
  async mounted() {
    if (this.$route.query.backTo) {
      this.backTo = this.$route.query.backTo;
    }
    const patientId = this.$route.query.patientId;
    if (patientId) {
      const response = await apiClient.patients.getPatient(patientId);
      if (response.ok) {
        this.selectPatient(response.data);
      } else {
        this.$router.push('/appointments/create/1');
      }
    }

    this.searchPatients();
  },
  data() {
    return {
      backTo: '/agenda',
      dateOptions: {
        hour: '2-digit',
        minute: '2-digit',
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      items: [
        {
          label: 'Selecionar Paciente',
          to: '/appointments/create/1',
          iconClass: 'bi-person-plus-fill',
        },
        {
          label: 'Agendar Consulta(s)',
          to: '/appointments/create/2',
          iconClass: 'bi-calendar-plus',
        },
        {
          label: 'Revisar Agendamento',
          to: '/appointments/create/3',
          iconClass: 'bi-check2-circle',
        },
      ],
      patientSearchTerm: '',
      selectedPatient: null,
      patients: [],
      isMultipleAppointments: false,
      appointment: {
        date: null,
        time: null,
        price: null,
        quantity: null,
        periodicity: null,
        duration: null,
      },
      periodicities: [
        {
          key: 'weekly',
          description: 'Semanal',
        },
        {
          key: 'biweekly',
          description: 'Quinzenal',
        },
        {
          key: 'monthly',
          description: 'Mensal',
        },
      ],
      priceFormatter: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      preview: null,
    };
  },
  methods: {
    async searchPatients() {
      const query = this.patientSearchTerm;
      const result = await apiClient.patients.listPatients(1, 10, query);

      if (result.ok) {
        this.patients = result.data.records;
      }
    },
    addPatient(name) {
      this.$router.push({
        path: '/patients/create',
        query: {
          redirect: this.$router.currentRoute.value.path,
          name: name,
        },
      });
    },
    selectPatient(patient) {
      this.selectedPatient = patient;
    },
    clearPatient() {
      this.selectedPatient = null;
    },
    nextStep() {
      const currentStep = this.currentStep();
      const next = currentStep + 1;
      this.$router.push({
        path: `/appointments/create/${next}`,
        query: { patientId: this.selectedPatient.id },
      });
      if (next === 3) {
        this.buildPreview();
      }
    },
    backStep() {
      const currentStep = this.currentStep();
      const next = currentStep - 1;
      this.$router.push({
        path: `/appointments/create/${next}`,
        query: { patientId: this.selectedPatient?.id },
      });
    },
    currentStep() {
      return parseInt(this.$route.params.step);
    },
    priceLabel() {
      if (this.isMultipleAppointments) return 'Preço do pacote';
      return 'Preço';
    },
    buildPreview() {
      const appointments = [];
      const quantity = this.appointment.quantity || 0;

      let nextDate = new Date(this.appointment.date);
      nextDate.setTime(
        nextDate.getTime() + nextDate.getTimezoneOffset() * 60000,
      );
      const time = this.appointment.time.split(':');
      nextDate.setHours(parseInt(time[0]));
      nextDate.setMinutes(parseInt(time[1]));

      if (this.isMultipleAppointments) {
        for (let i = 1; i <= quantity; i++) {
          appointments.push({
            date: nextDate,
          });

          nextDate = shared.calculateNextDate(
            nextDate,
            this.appointment.periodicity.key,
          );
        }
      } else {
        appointments.push({ date: nextDate });
      }

      const dueDate = new Date(this.appointment.date);
      dueDate.setTime(dueDate.getTime() + dueDate.getTimezoneOffset() * 60000);
      let financialRecords = [
        { dueDate: dueDate, price: this.appointment.price },
      ];

      this.preview = {
        appointments,
        financialRecords,
      };
    },
    async confirmAppointments() {
      const purchase = Object.assign({}, this.appointment);
      const date = new Date(this.appointment.date);
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
      const time = this.appointment.time.split(':');

      purchase.periodicity = this.appointment.periodicity?.key;
      purchase.patientId = this.selectedPatient.id;
      purchase.quantity = purchase.quantity || 1;
      purchase.date = date;
      purchase.date.setHours(parseInt(time[0]));
      purchase.date.setMinutes(parseInt(time[1]));

      purchase.price = Math.round(purchase.price * 100);

      const result = await apiClient.purchases.createPurchase(purchase);
      if (result.ok) {
        mixpanel.track('appointment-created');
        this.$toast({
          severity: 'success',
          title: 'Sucesso',
          message: 'Agendamento salvo com sucesso',
          life: 3000,
        });
        this.$router.push(this.backTo);
      }
    },
  },
  computed: {
    isValidAppointment() {
      const appointment = Object.assign({}, this.appointment);
      const errors = {};

      if (!this.isMultipleAppointments) appointment.quantity = 1;

      if (!appointment.price) errors['price'] = 'Preço é obrigatório';
      if (!appointment.date) errors['date'] = 'Data é Obrigatória';
      if (!appointment.quantity)
        errors['quantity'] = 'Quantidade é obrigatória';
      if (!appointment.duration) errors['duration'] = 'Duração é obrigatória';
      if (this.isMultipleAppointments && !appointment.periodicity)
        errors['periodicity'] = 'Periodicidade é obrigatória';

      return Object.keys(errors).length === 0;
    },
  },
};
</script>

<template>
  <div class="card create-appointment">
    <div class="card-body container">
      <div class="row justify-content-center">
        <div class="row mt-5 mb-5 stepper col-md-10">
          <div
            class="col step"
            :class="currentStep() > i ? 'done' : ''"
            v-for="(item, i) in items"
            :key="`${i}`">
            <div class="step-icon">
              <i class="bi" :class="item.iconClass"></i>
            </div>
            <div class="step-label">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>

      <!-- Patient Selection Step -->
      <div class="container" v-if="currentStep() === 1">
        <div
          class="row justify-content-center mt-6"
          v-if="!selectedPatient?.id">
          <div class="col-md-8 col-xl-7">
            <div class="row">
              <div class="col-9 col-xl-10">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar paciente"
                    aria-label="Buscar paciente"
                    v-model="patientSearchTerm"
                    @keypress.enter="searchPatients"
                    autofocus="true" />
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    v-on:click="searchPatients">
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
              <div class="col-1">
                <button
                  class="btn btn-primary"
                  v-on:click="addPatient(patientSearchTerm)">
                  <i class="bi bi-person-plus-fill"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center" v-if="!selectedPatient?.id">
          <div class="col-md-8 col-xl-7">
            <div
              class="row patient-card p-1"
              tabindex="0"
              v-for="patient in patients"
              :key="patient.id"
              v-on:click="selectPatient(patient)"
              v-on:keypress.enter="selectPatient(patient)"
              v-on:keypress.space="selectPatient(patient)">
              <div class="col text-capitalize">
                {{ patient.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center" v-if="selectedPatient?.id">
          <div class="col-md-8 col-xl-7">
            <div class="alert alert-light" role="alert">
              <i class="bi bi-person-fill"></i>
              {{ selectedPatient?.name }}
              <button
                type="button"
                class="btn btn-close btn-sm float-end"
                v-on:click="clearPatient"></button>
            </div>
          </div>
        </div>

        <div class="row justify-content-center" v-if="selectedPatient?.id">
          <div class="col-md-8 col-xl-7">
            <button
              class="btn btn-primary mt-4 me-4"
              @click="nextStep"
              :disabled="!selectedPatient?.id">
              Próximo
            </button>
          </div>
        </div>
      </div>
      <!-- End Patient Selection Step -->

      <!-- Appointment Step -->
      <div class="container col-md-8 col-xl-7" v-if="currentStep() === 2">
        <div class="alert alert-light" role="alert">
          <i class="bi bi-person-fill"></i>
          {{ selectedPatient?.name }}
        </div>
        <div class="row">
          <div class="mt-4 col-md-6">
            <div class="form-check form-switch d-flex flex-row">
              <input
                class="form-check-input switcher me-3"
                type="checkbox"
                role="switch"
                id="isMultipleAppointments"
                v-model="isMultipleAppointments" />
              <div class="switch-description">
                <h5>Criar Pacote de Consultas</h5>
                <p>Permite agendar um pacote com multiplas consultas</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-6 mb-3">
            <label for="date" class="form-label">Data</label>
            <input
              type="date"
              class="form-control"
              id="date"
              v-model="appointment.date"
              placeholder="DD/MM/AAAA" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="time" class="form-label">Horário</label>
            <input
              type="time"
              class="form-control"
              id="time"
              v-model="appointment.time" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="duration" class="form-label">Duração (minutos)</label>
            <input
              type="number"
              class="form-control"
              id="duration"
              v-model="appointment.duration" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="duration" class="form-label">{{ priceLabel() }}</label>
            <CurrencyInput
              id="price"
              v-model="appointment.price"
              class="form-control" />
          </div>
        </div>

        <div class="row" v-if="isMultipleAppointments">
          <div class="col-md-6 mb-3">
            <label for="periodicity" class="form-label">Periodicidade</label>
            <select
              class="form-select"
              id="periodicity"
              aria-label="Periodicidade"
              v-model="appointment.periodicity">
              <option
                :value="periodicity"
                v-for="periodicity in periodicities"
                :key="periodicity.key">
                {{ periodicity.description }}
              </option>
            </select>
          </div>

          <div class="col-md-6 mb-3">
            <label for="quantity" class="form-label">Quantidade</label>
            <input
              type="number"
              min="1"
              class="form-control"
              id="quantity"
              v-model="appointment.quantity" />
          </div>
        </div>

        <!-- <div class="row"> -->
        <div class="actions">
          <button
            class="btn btn-primary me-3"
            @click="nextStep"
            :disabled="!isValidAppointment">
            Próximo
          </button>
          <button class="btn btn-secondary" @click="backStep">Voltar</button>
        </div>
        <!-- </div> -->
      </div>
      <!-- End Appointment Step -->

      <div class="container" v-if="currentStep() === 3">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="summary-card">
              <h4>Dados do Paciente</h4>
              <span class="info name">
                <i class="pi pi-user"></i>
                {{ selectedPatient?.name }}<br />
              </span>
              <span class="info">
                <i class="pi pi-at"></i>
                {{ selectedPatient?.email }}<br />
              </span>
              <span class="info">
                <i class="pi pi-phone"></i>
                {{ selectedPatient?.phone }}<br />
              </span>
            </div>

            <div class="summary-card" v-if="isMultipleAppointments">
              <h4>Dados do Agendamento</h4>
              <span class="info">
                <i class="pi pi-refresh"></i> Periodicidade:
                {{ appointment.periodicity.description }}
              </span>
              <span class="info">
                <i class="pi pi-shopping-cart"></i> Quantidade:
                {{ appointment.quantity }}
              </span>
              <span class="info">
                <i class="pi pi-money-bill"></i>
                Preço total:
                {{ priceFormatter.format(appointment.price) }}
              </span>
            </div>
            <div class="summary-card mt-4">
              <h4 v-if="!isMultipleAppointments">Data da consulta</h4>
              <h4 v-if="isMultipleAppointments">Datas das consultas</h4>
              <span
                class="info"
                v-for="appointment in preview?.appointments"
                v-bind:key="appointment.date.getTime()">
                <i class="pi pi-calendar"></i>
                {{ appointment.date.toLocaleDateString('pt-BR', dateOptions)
                }}<br />
              </span>
            </div>

            <div class="summary-card mt-4">
              <h4>Lançamentos Financeiros</h4>
              <span
                class="info"
                v-for="record in preview?.financialRecords"
                v-bind:key="record.dueDate.getTime()">
                {{ priceFormatter.format(record.price) }} -
                {{
                  record.dueDate.toLocaleDateString('pt-BR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })
                }}
              </span>
            </div>

            <div class="actions mt-6">
              <button class="btn btn-primary me-3" @click="confirmAppointments">
                Confirmar
              </button>
              <button class="btn btn-secondary" @click="backStep">
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.create-appointment {
  .switcher {
    min-width: 25px;
  }
  .switch-description {
    h5 {
      font-size: 14px;
      font-weight: bold;
    }

    p {
      font-size: 12px;
      margin: 0;
    }
  }

  .summary-card {
    display: block;
    font-size: 16px;
    h4 {
      margin: 10px 0;
      font-weight: bold;
      color: #3d78bc;
      font-size: 18px;
    }
    .info {
      margin: 10px 0px;
      display: block;
    }
  }

  .stepper {
    @media screen and (max-width: 400px) {
      display: none !important;
    }

    .step {
      width: 100%;

      .step-icon {
        display: block;
        background-color: #dfdfdf;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        color: #636363;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
      }
      &::before {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        top: 31px;
        left: calc(-50% + 35px);
        background-color: #dfdfdf;
        width: calc(100% - 70px);
      }

      .step-label {
        text-align: center;
        margin-top: 20px;
        font-size: 15px;
        font-weight: bold;
        color: #6c6c6c;
      }

      &.done {
        .step-icon {
          background-color: rgb(32, 120, 186);
          color: #ffffff;
        }
        &::before {
          background-color: rgb(32, 120, 186);
        }
      }
    }

    .step:first-child {
      &::before {
        display: none;
      }
    }
  }

  .patient-card {
    &:hover {
      background-color: #efefef;
      cursor: pointer;
    }
  }
}
</style>
