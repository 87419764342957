<script setup>
import apiClient from '../../util/api-client';
</script>

<template>
  <div class="request-recover">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-4">Recuperar Conta</h5>
        <form @submit.prevent="resetPassword">
          <div class="form-group mb-4">
            <label for="password">Senha</label>
            <input
              id="password"
              type="password"
              class="form-control"
              v-model="password"
              :class="getClass('password')"
              required />
            <small class="form-text text-danger" v-if="errors.password">{{
              $t(errors.password)
            }}</small>
          </div>
          <div class="form-group mb-4">
            <label for="confirmPassword">Confirmar Senha</label>
            <input
              id="confirmPassword"
              type="password"
              class="form-control"
              v-model="confirmPassword"
              :class="getClass('confirmPassword')"
              required />
            <small
              class="form-text text-danger"
              v-if="errors.confirmPassword"
              >{{ $t(errors.confirmPassword) }}</small
            >
          </div>
          <button type="submit" class="btn btn-primary mt-3">
            Alterar Senha
          </button>
          <router-link class="btn btn-link mt-3" to="/login">Login</router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: null,
      confirmPassword: null,
      errors: {},
    };
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  methods: {
    async resetPassword() {
      this.errors = this.validate();
      if (Object.keys(this.errors).length === 0) {
        const result = await apiClient.account.resetPassword(
          this.code,
          this.password,
        );
        if (result.ok) {
          this.$toast({
            severity: 'success',
            title: 'Sucesso',
            message: 'Senha alterada com sucessi!',
            life: 5000,
          });
          this.$router.push('/login');
        } else {
          this.$toast({
            severity: 'danger',
            title: 'Ops!',
            message: this.$t(result.errors),
            life: 5000,
          });
        }
      }
    },
    validate() {
      const errors = {};

      if (!this.password) errors.password = 'Senha é obrigatória';
      if (!this.confirmPassword)
        errors.confirmPassword = 'Confirmação de senha é obrigatória';

      const passwordRegex =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
      if (this.password && !passwordRegex.test(this.password))
        errors.password =
          'Senha fraca! Por favor, escolha uma senha mais segura, utilizando letras maiúsculas, minúsculas e números.';

      if (this.password !== this.confirmPassword)
        errors.confirmPassword = 'Confirmação de senha não é igual a senha';

      return errors;
    },
    getClass(field) {
      if (this.errors[field]) return 'p-invalid';
      return '';
    },
  },
};
</script>

<style lang="scss">
.request-recover {
  display: flex;
  align-items: center;
  .card {
    width: 100%;
    max-width: 500px;
  }
}
</style>
