<script setup>
import { vMaska } from 'maska';

import apiClient from '../../util/api-client';
import mixpanel from '../../util/mixpanel';
import validateCPF from '../../util/validate-cpf';
</script>

<script>
function createOrUpdatePatient(patient) {
  if (patient.id) {
    return apiClient.patients.updatePatient(patient.id, patient);
  } else {
    return apiClient.patients.createPatient(patient);
  }
}

export default {
  async mounted() {
    this.redirectTo = this.$route.query.redirect;
    if (this.$route.query.name) this.patient.name = this.$route.query.name;

    if (this.$route.params.id) {
      this.patientId = this.$route.params.id;
      const result = await apiClient.patients.getPatient(this.patientId);
      if (result.ok) {
        this.patient = result.data;
      }
    }
  },
  data() {
    return {
      redirectTo: null,
      patientId: null,
      errors: {},
      patientAge: null,
      patient: {
        name: '',
        email: '',
        phone: '',
        bithday: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        cpf: '',
        rg: '',
      },
    };
  },
  methods: {
    getClass(field) {
      if (this.errors[field]) return 'p-invalid';
      return '';
    },
    getTitle() {
      if (this.patientId) return 'Editar Paciente';
      return 'Novo Paciente';
    },
    async savePatient(event) {
      event.preventDefault();

      const errors = this.validate();
      this.errors = errors;

      if (Object.keys(this.errors).length === 0) {
        const response = await createOrUpdatePatient(this.patient);

        if (!response.ok) {
          this.errors = response.errors;
        } else {
          mixpanel.track('patient-created');
          this.$toast({
            severity: 'success',
            title: 'Sucesso',
            message: 'Paciente salvo com sucesso',
            life: 3000,
          });
          const dest = this.redirectTo ? this.redirectTo : '/patients';
          this.$router.push({
            path: dest,
            query: { patientId: response.patient.id },
          });
        }
      }
    },
    validate() {
      const errors = {};

      if (!this.patient.name) errors.name = 'Nome é obrigatório';
      if (!this.patient.email) errors.email = 'Email é obrigatório';
      if (!this.patient.phone) errors.phone = 'Telefone é obrigatório';

      if (this.patient.cpf && !validateCPF(this.patient.cpf))
        errors.cpf = 'CPF inválido';

      return errors;
    },
  },

  watch: {
    'patient.birthday': function (value) {
      const parts = value.split('/');
      if (parts.length === 3 && parts[2].length === 4) {
        const startDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
        const endDate = new Date(); // Current date
        const differenceInMilliseconds = endDate - startDate;
        const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Considering leap years
        const age = Math.floor(differenceInMilliseconds / millisecondsInYear);
        if (age && !isNaN(age) && age < 110 && age >= 0) {
          this.patientAge = age;
        } else {
          this.patientAge = null;
        }
      } else {
        this.patientAge = null;
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title mb-4">{{ getTitle() }}</h5>
      <form @submit="savePatient" class="patient-form">
        <div class="mb-3">
          <label class="form-label" for="name">Nome</label>
          <div class="input-group">
            <span class="input-group-text">
              <i class="bi bi-person-fill"></i>
            </span>
            <input
              id="name"
              type="text"
              v-model="patient.name"
              class="form-control"
              v-bind:class="getClass('name')" />
          </div>
          <small class="error-message" v-if="errors.name">{{
            $t(errors.name)
          }}</small>
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">Email</label>
          <div class="input-group">
            <span class="input-group-text">
              <i class="bi bi-at"></i>
            </span>
            <input
              id="email"
              type="text"
              v-model="patient.email"
              class="form-control"
              v-bind:class="getClass('email')" />
          </div>
          <small class="error-message" v-if="errors.email">{{
            $t(errors.email)
          }}</small>
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">Telefone</label>
          <div class="input-group">
            <span class="input-group-text">
              <i class="bi bi-phone"></i>
            </span>
            <input
              id="phone"
              type="text"
              v-model="patient.phone"
              class="form-control"
              v-bind:class="getClass('phone')"
              v-maska
              data-maska="['(##) # ####-####','(##) ####-####']" />
          </div>
          <small class="error-message" v-if="errors.phone">{{
            $t(errors.phone)
          }}</small>
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">Data de Nascimento</label>
          <input
            id="birthday"
            type="text"
            v-model="patient.birthday"
            class="form-control"
            v-maska
            data-maska="##/##/####" />
          <div class="mt-1 patient-age" v-if="patientAge">
            Idade atual: {{ patientAge }}
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">CPF</label>
          <input
            id="cpf"
            type="text"
            v-model="patient.cpf"
            class="form-control"
            v-maska
            data-maska="###.###.###-##" />
          <small class="error-message" v-if="errors.cpf">{{
            $t(errors.cpf)
          }}</small>
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">RG</label>
          <input
            id="rg"
            type="text"
            v-model="patient.rg"
            class="form-control" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">Endereço</label>
          <input
            id="address"
            type="text"
            v-model="patient.address"
            class="form-control" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">Cidade</label>
          <input
            id="city"
            type="text"
            v-model="patient.city"
            class="form-control" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">Estado</label>
          <input
            id="state"
            type="text"
            v-model="patient.state"
            class="form-control" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">CEP</label>
          <input
            id="zipCode"
            type="text"
            v-model="patient.zipCode"
            class="form-control" />
        </div>
        <div class="d-grid gap-2 d-md-block">
          <button type="submit" class="btn btn-primary">Salvar</button>
          <button class="btn btn-link" v-on:click="$router.push('/patients')">
            Voltar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.patient-form {
  .patient-age {
    color: #6a6a6a;
    font-weight: bold;
    font-size: 12px;
  }
}
</style>
