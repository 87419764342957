<script setup>
import { vMaska } from 'maska';

import apiClient from '../../util/api-client';
import mixpanel from '../../util/mixpanel';
import sessionStorage from '../../util/storage/session-storage';
</script>

<script>
export default {
  mounted() {
    mixpanel.track('create-account-opened');
  },
  methods: {
    async createAccount(e) {
      e.preventDefault();

      const errors = this.validate();
      this.errors = errors;

      if (Object.keys(this.errors).length === 0) {
        try {
          const response = await apiClient.account.createAccount(this.user);
          if (!response.created) {
            this.errors = response.errors;
          } else {
            mixpanel.track('accunt-created', {
              $email: this.user.email,
              $name: this.user.name,
            });
            sessionStorage.storeToken(response.token);
            this.$router.push('/');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        mixpanel.track('account-creation-form-error', {
          $email: this.user.email,
          $name: this.user.name,
        });
      }
    },
    getClass(field) {
      if (this.errors[field]) return 'p-invalid';
      return '';
    },
    validate() {
      const errors = {};

      if (!this.user.name) errors.name = 'Nome é obrigatório';
      if (!this.user.email) errors.email = 'Email é obrigatório';
      if (!this.user.phone) errors.phone = 'Telefone é obrigatório';
      if (!this.user.password) errors.password = 'Senha é obrigatória';
      if (!this.user.confirmPassword)
        errors.confirmPassword = 'Confirmação de senha é obrigatória';

      const passwordRegex =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
      if (this.user.password && !passwordRegex.test(this.user.password))
        errors.password =
          'Senha fraca! Por favor, escolha uma senha mais segura, utilizando letras maiúsculas, minúsculas e números.';

      if (this.user.password !== this.user.confirmPassword)
        errors.confirmPassword = 'Confirmação de senha não é igual a senha';

      return errors;
    },
  },
  data() {
    return {
      specialties: [
        'Psicologia',
        'Nutrição',
        'Médicina',
        'Terapia ocupacional',
        'Fisioterapia',
        'Outro',
      ],
      user: {
        email: '',
        name: '',
        phone: '',
        password: '',
        confirmPassword: '',
        specialty: '',
      },
      errors: {},
    };
  },
};
</script>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-8">
        <div class="card rounded bg-white shadow-sm border-0">
          <div class="card-body">
            <h5 class="card-title mb-5">Criar Conta</h5>
            <form @submit="createAccount">
              <div class="form-group mb-4">
                <label for="name">Nome</label>
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  v-model="user.name"
                  :class="getClass('name')"
                  required />
                <small class="form-text text-danger" v-if="errors.name">{{
                  $t(errors.name)
                }}</small>
              </div>
              <div class="form-group mb-4">
                <label for="email">Email</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="bi bi-at"></i>
                  </span>
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    v-model="user.email"
                    :class="getClass('email')"
                    required />
                </div>
                <small class="form-text text-danger" v-if="errors.email">{{
                  $t(errors.email)
                }}</small>
              </div>
              <div class="form-group mb-4">
                <label for="email">Telefone</label>
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="bi bi-phone"></i>
                  </span>
                  <input
                    id="phone"
                    type="text"
                    v-model="user.phone"
                    class="form-control"
                    v-bind:class="getClass('phone')"
                    v-maska
                    data-maska="['(##) # ####-####','(##) ####-####']" />
                </div>
                <small class="form-text text-danger" v-if="errors.phone">{{
                  $t(errors.phone)
                }}</small>
              </div>
              <div class="form-group mb-4">
                <label for="specialty">Especialidade</label>
                <div class="input-group">
                  <select class="form-select" v-model="user.specialty">
                    <option v-for="s in specialties" :key="s">
                      {{ s }}
                    </option>
                  </select>
                </div>
                <small class="form-text text-danger" v-if="errors.specialty">{{
                  $t(errors.specialty)
                }}</small>
              </div>
              <div class="form-group mb-4">
                <label for="password">Senha</label>
                <input
                  id="password"
                  type="password"
                  class="form-control"
                  v-model="user.password"
                  :class="getClass('password')"
                  required />
                <small class="form-text text-danger" v-if="errors.password">{{
                  $t(errors.password)
                }}</small>
              </div>
              <div class="form-group mb-4">
                <label for="confirmPassword">Confirmar Senha</label>
                <input
                  id="confirmPassword"
                  type="password"
                  class="form-control"
                  v-model="user.confirmPassword"
                  :class="getClass('confirmPassword')"
                  required />
                <small
                  class="form-text text-danger"
                  v-if="errors.confirmPassword"
                  >{{ $t(errors.confirmPassword) }}</small
                >
              </div>
              <button type="submit" class="btn btn-primary">Criar Conta</button>
              <button
                type="button"
                class="btn btn-link"
                @click="$router.push('/login')">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
