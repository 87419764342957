import Emiter from 'tiny-emitter';

export const eventEmitter = new Emiter();

function confirmation(options) {
  eventEmitter.emit('confirm-requested', options);
}

const DialogPlugin = {
  install(app) {
    app.config.globalProperties.$confirm = confirmation;
  },
};

export default DialogPlugin;
