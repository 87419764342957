<script setup>
import { Modal } from 'bootstrap';

import apiClient from '../../util/api-client';
</script>

<template>
  <div class="appointment-actions-container">
    <!-- <router-link
      v-if="!appointment.patientRecordId"
      class="attach-link"
      :to="`/patients/${appointment.patientId}/appointments/${appointment.id}/add-record`">
      <i
        class="bi bi-file-earmark-plus"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-title="Adicionar documento"></i>
    </router-link> -->
    <router-link
      v-if="appointment.patientRecordId"
      class="attach-link"
      :to="`/patients/${appointment.patientId}/records/${appointment.patientRecordId}`">
      <i
        class="bi bi-journal-text"
        v-tooltip
        data-bs-toggle="tooltip"
        data-bs-title="Visualizar documento"></i>
    </router-link>

    <div class="btn-group">
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :class="cleanButtonClass()">
        Ações
      </button>
      <ul class="dropdown-menu">
        <span v-if="appointment.status !== 'pending'">
          <li>
            <a
              class="dropdown-item"
              href="#"
              v-on:click.prevent="pending(appointment, $event)"
              >Marcar como pendente</a
            >
          </li>
        </span>
        <span v-if="appointment.status === 'pending'">
          <li>
            <a
              class="dropdown-item"
              href="#"
              v-on:click.prevent="complete(appointment, $event)"
              >Concluir</a
            >
          </li>
          <li>
            <a
              class="dropdown-item"
              href="#"
              v-on:click.prevent="cancel(appointment, $event)"
              >Cancelar</a
            >
          </li>
          <li>
            <a
              class="dropdown-item"
              href="#"
              v-on:click.prevent="openRescheduleModal(appointment, $event)"
              >Remarcar</a
            >
          </li>
        </span>
      </ul>
    </div>
    <Teleport to="body">
      <div class="modal fade" tabindex="-1" ref="reschedulingModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Remarcar Consulta
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row mb-4">
                <div class="col-md-6">
                  <label for="date" class="form-label">Data</label>
                  <input
                    type="date"
                    class="form-control"
                    id="date"
                    placeholder="DD/MM/AAAA"
                    v-model="rescheduling.newDate" />
                </div>
                <div class="col-md-6">
                  <label for="time" class="form-label">Horário</label>
                  <input
                    type="time"
                    class="form-control"
                    id="time"
                    v-model="rescheduling.newTime" />
                </div>
                <div class="col-md-6">
                  <label for="time" class="form-label mt-3">Duração</label>
                  <input
                    type="number"
                    class="form-control"
                    id="time"
                    v-model="rescheduling.newDuration" />
                </div>
              </div>
              <div class="form-check" v-if="rescheduling.isBatch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="rescheduleNext"
                  v-model="rescheduling.rescheduleNext" />
                <label class="form-check-label" for="rescheduleNext">
                  Remarcar próximos
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                v-on:click="closeRescheduleModal">
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="reschedule(appointment)">
                Remarcar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
// this is a workarrownd to access the modal on beforeRouteLeave
// for some reason, this.eventModal is undefined on that.
let _reschedulingModal;

export default {
  props: ['appointment', 'cleanButton'],
  mounted() {
    const reschedulingModalEl = this.$refs.reschedulingModal;
    if (!this.reschedulingModal)
      this.reschedulingModal = new Modal(reschedulingModalEl, {});

    _reschedulingModal = this.reschedulingModal; // setting the reschedulingModal for workarround
    window.rm = this.reschedulingModal;
    window.rmel = reschedulingModalEl;
  },
  beforeRouteLeave(_to, from, next) {
    if (
      _reschedulingModal._isShown &&
      window.history.state.back === from.path
    ) {
      next(false);
      _reschedulingModal.hide();
    } else {
      next();
    }
  },
  data() {
    return {
      rescheduling: {
        isBatch: false,
        newDate: null,
        newTime: null,
        newDuration: null,
        rescheduleNext: false,
      },
    };
  },
  methods: {
    async complete(appointment) {
      this.$emit('complete-requested');
      this.$confirm({
        message: 'Deseja marcar a consulta como concluída?',
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-success',
        acceptLabel: 'Concluir',
        rejectLabel: 'Voltar',
        onAccept: async () => {
          const result = await apiClient.appointments.completeAppointment(
            appointment.id,
          );
          if (result.ok) {
            this.$emit('set-complete', appointment);
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Consulta concluída com sucesso!',
              life: 3000,
            });
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
        onReject: () => {
          this.$emit('complete-rejected');
        },
      });
    },

    async cancel(appointment) {
      let choices = [];
      let message = 'Confirmar cancelamento de consulta?';
      if (appointment.purchase?.appointmentCount > 1) {
        choices = [
          {
            key: 'current',
            label: 'Apenas esta',
          },
          {
            key: 'current-and-next',
            label: 'Esta e as próximas',
          },
          {
            key: 'all',
            label: 'Todas',
          },
        ];
        message = 'Deseja cancelar quais consultas?';
      }

      this.$emit('cancel-requested');
      this.$confirm({
        message: message,
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-danger',
        acceptLabel: 'Cancelar',
        rejectLabel: 'Voltar',
        onAccept: async selectedChoice => {
          const cancelNext = selectedChoice?.key === 'current-and-next';
          const cancelAll = selectedChoice?.key === 'all';
          const result = await apiClient.appointments.cancelAppointment(
            appointment.id,
            { cancelNext, cancelAll },
          );
          if (result.ok) {
            this.$emit('set-canceled');
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Consulta cancelada com sucesso!',
              life: 3000,
            });
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
        onReject: () => {
          this.$emit('cancel-rejectet');
        },
        choices: choices,
      });
    },

    async pending(appointment) {
      this.$emit('pending-requested');
      this.$confirm({
        message: 'Deseja marcar a consulta como pendente?',
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-warning',
        acceptLabel: 'Marcar como pendente',
        rejectLabel: 'Voltar',
        onAccept: async () => {
          const result = await apiClient.appointments.pendentAppointment(
            appointment.id,
          );
          if (result.ok) {
            this.$emit('set-pending', appointment);
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Consulta marcada como pendente!',
              life: 3000,
            });
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
        onReject: () => {
          this.$emit('pending-rejected');
        },
      });
    },

    async openRescheduleModal(appointment) {
      const _appointment = (
        await apiClient.appointments.findById(appointment.id)
      ).data;

      this.rescheduling.isBatch = _appointment.purchase?.appointmentCount > 1;

      this.$emit('rescheduling-opened');
      this.reschedulingModal.show();
      const startAt = new Date(appointment.startAt);
      const endAt = new Date(appointment.endAt);

      const duration = (endAt.getTime() - startAt.getTime()) / 60_000;

      const dateString = `${startAt.getFullYear()}-${(startAt.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${startAt.getDate().toString().padStart(2, '0')}`;
      const timeString = `${startAt
        .getHours()
        .toString()
        .padStart(2, '0')}:${startAt.getMinutes().toString().padStart(2, '0')}`;
      this.rescheduling.newDate = dateString;
      this.rescheduling.newTime = timeString;
      this.rescheduling.newDuration = duration;
    },

    closeRescheduleModal() {
      this.reschedulingModal.hide();
      this.$emit('rescheduling-closed');
    },

    async reschedule(appointment) {
      const rescheduleNext = this.rescheduling.rescheduleNext;
      const newDuration = this.rescheduling.newDuration;
      const newStartAt = new Date(this.rescheduling.newDate);
      newStartAt.setTime(
        newStartAt.getTime() + newStartAt.getTimezoneOffset() * 60000,
      );
      const time = this.rescheduling.newTime.split(':');
      newStartAt.setHours(parseInt(time[0]));
      newStartAt.setMinutes(parseInt(time[1]));
      this.reschedulingModal.hide();
      this.$confirm({
        message: 'Deseja remarcar a consulta?',
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-success',
        acceptLabel: 'Remarcar',
        rejectLabel: 'Voltar',
        onAccept: async () => {
          this.rescheduling = {
            newDate: null,
            newTime: null,
            newDuration: null,
            rescheduleNext: false,
          };
          const result = await apiClient.appointments.rescheduleAppointment(
            appointment.id,
            newStartAt,
            newDuration,
            rescheduleNext,
          );
          if (result.ok) {
            this.$emit('rescheduled', appointment);
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Consulta remarcada com sucesso!',
              life: 3000,
            });
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
        onReject: () => {
          this.reschedulingModal.show();
        },
      });
    },
    cleanButtonClass() {
      if (this.cleanButton) return 'clean-button';
    },
  },
};
</script>

<style lang="scss">
.appointment-actions-container {
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;

  .clean-button {
    border: none;
  }

  .attach-link {
    font-size: 25px;
    margin-right: 20px;

    &.disabled {
      color: #d3d3d3;
      cursor: not-allowed;
    }
  }
}
</style>
