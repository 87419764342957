<script setup>
import { Modal } from 'bootstrap';

import { eventEmitter } from './dialog-plugin';
</script>

<script>
export default {
  mounted() {
    const modal = this.$refs.modal;
    eventEmitter.on('confirm-requested', options => {
      this.title = options.title;
      this.message = options.message;

      this.acceptLabel = options.acceptLabel || 'Confirmar';
      this.acceptClass = options.acceptClass || 'btn-primary';

      this.rejectLabel = options.rejectLabel || 'Cancelar';
      this.rejectClass = options.rejectClass || 'btn-secondary';

      const emptyFn = () => {};
      this.onAccept = options.onAccept || emptyFn;
      this.onReject = options.onReject || emptyFn;

      this.choices = options.choices;
      this.choiceType = options.choiceType;

      if (this.choices?.length > 0) this.selectedChoice = this.choices[0];

      if (!this.dialog) this.dialog = new Modal(modal, {});
      this.dialog.show();
    });
  },
  data() {
    return {
      title: null,
      message: null,
      acceptClass: 'btn-primary',
      acceptLabel: 'Confirmar',
      rejectClass: 'btn-secondary',
      rejectLabel: 'Cancelar',
      onAccept: () => {},
      onReject: () => {},
      dialog: null,
      choices: null,
      choiceType: 'radio',
      selectedChoice: null,
    };
  },
  methods: {
    callAccept() {
      this.onAccept(this.selectedChoice);
      this.dialog.hide();
    },

    callReject() {
      this.onReject(this.selectedChoice);
      this.dialog.hide();
    },
  },
};
</script>

<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            {{ title }}
          </h1>
        </div>
        <div class="modal-body">
          <p>
            {{ message }}
          </p>
          <div v-if="choices">
            <div class="form-check" v-for="choice in choices" :key="choice.key">
              <input
                class="form-check-input"
                type="radio"
                :name="choice.key"
                :id="choice.key"
                :value="choice"
                v-model="selectedChoice" />
              <label class="form-check-label" :for="choice.key">
                {{ choice.label }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn"
            :class="rejectClass"
            v-on:click="callReject">
            {{ rejectLabel }}
          </button>
          <button
            type="button"
            class="btn"
            :class="acceptClass"
            v-on:click="callAccept">
            {{ acceptLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
