<script setup>
import { nanoid } from 'nanoid';

import Loader from '../components/Loader.vue';
import apiClient from '../util/api-client';
</script>

<template>
  <div class="monthly-list container-fluid p-0">
    <div class="d-md-flex justify-content-start">
      <div class="d-flex flex-row flex-grow-1 mb-3">
        <div class="btn-group flex-grow-1 flex-md-grow-0" role="group">
          <button
            type="button"
            class="btn btn-outline-primary btn-arrow btn-sm"
            v-on:click="previousMonth">
            <i class="bi bi-chevron-left"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-md btn-today">
            {{ getCurrentMonth() }}
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-arrow btn-sm"
            v-on:click="nextMonth">
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
      </div>
      <div>
        <slot name="actions"></slot>
      </div>
    </div>
    <Loader v-if="loading" />
    <div v-if="!loading">
      <slot name="header"></slot>
      <slot name="empty" v-if="isEmpty"></slot>
      <div class="item" v-for="item in items" :key="item.id || nanoid()">
        <slot name="item" v-bind="item">
          {{ item }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['apiPath'],
  data() {
    return {
      loading: true,
      items: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;

      this.$emit('month-selected', this.selectedMonth);
      const res = await apiClient
        .client()
        .get(`${this.apiPath}?month=${this.selectedMonth}`);
      this.items = res.data.records;
      this.loading = false;
    },

    getCurrentMonth() {
      if (this.selectedMonth) {
        const [currentYear, currentMonth] = this.selectedMonth.split('-');
        const currentMonthDate = new Date(currentYear, currentMonth - 1);
        return currentMonthDate.toLocaleDateString('pt-BR', {
          month: 'long',
          year: 'numeric',
        });
      }
    },

    openMonth(month) {
      const currentQuery = this.$route.query;
      this.$router.push({
        path: this.$route.path,
        query: { ...currentQuery, month: month },
      });
    },

    nextMonth() {
      const [currentYear, currentMonth] = this.selectedMonth.split('-');
      const nextMonth = new Date(currentYear, currentMonth - 1);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      const year = nextMonth.getFullYear();
      const month = String(nextMonth.getMonth() + 1).padStart(2, '0');
      this.openMonth(`${year}-${month}`);
    },

    previousMonth() {
      const [currentYear, currentMonth] = this.selectedMonth.split('-');
      const nextMonth = new Date(currentYear, currentMonth - 1);
      nextMonth.setMonth(nextMonth.getMonth() - 1);
      const year = nextMonth.getFullYear();
      const month = String(nextMonth.getMonth() + 1).padStart(2, '0');
      this.openMonth(`${year}-${month}`);
    },
  },
  computed: {
    selectedMonth() {
      const month = this.$route.query.month;
      if (!month) {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        return `${year}-${month.toString().padStart(2, '0')}`;
      }
      return month;
    },
    isEmpty() {
      return this.items.length < 1;
    },
  },

  watch: {
    selectedMonth() {
      this.loadData();
    },
  },
};
</script>

<style lang="scss">
.monthly-list {
  .btn-arrow {
    max-width: 50px;
  }
}
</style>
