<script setup>
import Editor from '../../components/Editor.vue';
import InputTag from '../../components/InputTag.vue';
import apiClient from '../../util/api-client';
</script>

<template>
  <div class="patient-record-form card">
    <div class="card-body">
      <h5 class="card-title mb-4">{{ getTitle() }}</h5>
      <form @submit="savePatientRecord">
        <div class="mb-3">
          <label class="form-label" for="keywords">Palavras-chave</label>
          <label
            class="question-mark"
            data-bs-toggle="tooltip"
            data-bs-title="Dica: Utilize palavras-chave para organizar e facilitar a busca de documentos importantes do paciente no futuro. Isso é necessário, pois o conteúdo é criptografado para garantir a segurança das informações."
            v-tooltip>
            <i class="bi bi-question-circle"></i>
          </label>
          <InputTag
            id="some-id"
            placeholder="Adicione palavras-chave relevantes para categorizar o documento..."
            v-model="keywords" />
          <small class="error-message mb-3" v-if="errors.keywords">{{
            $t(errors.keywords)
          }}</small>
        </div>
        <div class="mb-3">
          <label class="form-label" for="name">Conteúdo</label>
          <label
            class="question-mark"
            data-bs-toggle="tooltip"
            data-bs-title="Dica: O conteúdo deste documento é criptografado para proteger a privacidade do paciente. Insira aqui as informações relevantes e confidenciais da sessão."
            v-tooltip>
            <i class="bi bi-question-circle"></i>
          </label>
          <div class="editor">
            <Editor
              toolbar="essential"
              v-model="content"
              placeholder="Insira aqui o conteúdo confidencial relacionado à consulta..." />
          </div>
          <small class="error-message" v-if="errors.content">{{
            $t(errors.content)
          }}</small>
        </div>
        <input type="submit" class="btn btn-primary mt-3" value="Salvar" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: [],
      content: '',
      errors: {},
    };
  },
  methods: {
    getTitle() {
      return 'Novo registro';
    },
    async savePatientRecord(e) {
      e.preventDefault();
      const patientId = this.$route.params.patientId;
      const appointmentId = this.$route.params.appointmentId;

      const creation = await apiClient.patients.createPatientRecord(patientId, {
        appointmentId: appointmentId,
        keywords: this.keywords,
        content: this.content,
      });

      if (creation.ok) {
        this.$toast({
          severity: 'success',
          title: 'Sucesso',
          message: 'Documento salvo com sucesso',
          life: 3000,
        });
        this.$router.back();
      } else {
        this.errors = creation.errors;
      }
    },
  },
};
</script>

<style lang="scss">
.patient-record-form {
  .question-mark {
    color: var(--primary);
    margin-left: 8px;
    line-height: 15px;
    font-size: 15px;
  }
  .editor {
    height: 500px;
    .ql-container {
      height: calc(100% - 55px);

      .ql-editor {
        font-size: 12pt;
      }
    }
  }
}
</style>
