<script setup>
import Loader from '../../components/Loader.vue';
import apiClient from '../../util/api-client';
</script>

<template>
  <div>
    <div>
      <div class="d-flex justify-content-end">
        <h3 class="col month-title">
          {{
            beginingOfMonth.toLocaleDateString('pt-BR', {
              month: 'long',
              year: 'numeric',
            })
          }}
        </h3>
        <div class="btn-group calendar-nav mb-3 align-self-end" role="group">
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            v-on:click="previousMonth">
            <i class="bi bi-chevron-left"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm btn-today"
            v-on:click="openToday">
            Hoje
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            v-on:click="nextMonth">
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
    <Loader v-if="loading" />
    <div v-if="!loading" class="calendar">
      <div class="row headers">
        <div class="col">Dom</div>
        <div class="col">Seg</div>
        <div class="col">Ter</div>
        <div class="col">Qua</div>
        <div class="col">Qui</div>
        <div class="col">Sex</div>
        <div class="col">Sab</div>
      </div>
      <div class="row calendar-days" v-for="(row, idx) in calendar" :key="idx">
        <div class="col" v-for="(day, i) in row" :key="`${idx}-${i}`" nowrap>
          <div class="day" :class="dynamicClass(day.day)">
            <div class="day-title">
              {{ day.day.getDate() }}
            </div>
            <div class="day-events">
              <span v-for="(event, e) in day.events" :key="`${idx}-${i}-${e}`">
                <div
                  class="event"
                  v-if="e < 5"
                  v-on:click="emitEvent('eventClicked', event)">
                  <span class="event-marker" />
                  {{ event.startAt.getHours().toString().padStart(2, '0') }}:{{
                    event.startAt.getMinutes().toString().padStart(2, '0')
                  }}
                  {{ event.description }}
                </div>
              </span>
              <div
                v-if="day.events?.length > 5"
                class="more"
                data-bs-toggle="modal"
                :data-bs-target="`#modalDay-${idx}-${i}`">
                mais {{ day.events?.length - 5 }}
              </div>
              <div
                class="modal fade"
                tabindex="-1"
                :id="`modalDay-${idx}-${i}`">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">
                        {{
                          day.day.toLocaleDateString('pt-BR', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          })
                        }}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    </div>
                    <div class="modal-body event-modal">
                      <div
                        class="event"
                        v-for="(event, e) in day.events"
                        :key="`${idx}-${i}-${e}`"
                        v-on:click="emitEvent('eventClicked', event)">
                        <span class="event-marker" />{{
                          event.startAt.getHours()
                        }}:{{ event.startAt.getMinutes() }}
                        {{ event.description }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function parseEvents(events) {
  return events.map(e => {
    const startAt = new Date(e.startAt);
    const endAt = new Date(e.endAt);

    return {
      id: e.id,
      startAt: startAt,
      endAt: endAt,
      description: e.patientName,
      duration: (endAt.getTime() - startAt.getTime()) / 60000,
      status: e.status,
      patient: {
        id: e.patientId,
        name: e.patientName,
        phone: e.patientPhone,
        email: e.patientEmail,
      },
    };
  });
}

export default {
  beforeMount() {
    const now = new Date();
    this.currentYear = now.getFullYear();
    this.currentMonth = now.getMonth();
    this.setupDates();
  },
  mounted() {
    this.loadCalendar();
    this.eventBus.on('force-update', () => {
      this.loadCalendar();
    });
  },
  props: ['eventBus'],
  expose: ['firstDay', 'lastDay'],
  data() {
    return {
      loading: false,
      calendar: null,
      events: [],
    };
  },
  methods: {
    dynamicClass(day) {
      const classes = [];
      const now = new Date();
      if (
        day.getTime() < this.beginingOfMonth.getTime() ||
        day.getTime() > this.endOfMonth.getTime()
      ) {
        classes.push('other-month');
      }

      if (
        day.getFullYear() === now.getFullYear() &&
        day.getMonth() === now.getMonth() &&
        day.getDate() === now.getDate()
      ) {
        classes.push('today');
      }

      return classes.join(' ');
    },
    async getEvents(start, end) {
      this.loading = true;
      if (start && end) {
        const result = await apiClient.appointments.listAppointments(
          start,
          end,
        );
        if (result.ok) {
          this.loading = false;
          return result.data;
        }
      } else {
        console.warn('api called with undefined dates');
      }
      this.loading = false;
    },
    setupDates() {
      const beginingOfMonth = new Date(this.currentYear, this.currentMonth, 1);
      this.beginingOfMonth = beginingOfMonth;
      this.endOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
    },
    async loadCalendar() {
      this.setupDates();

      const firstDay = new Date(
        this.beginingOfMonth.getFullYear(),
        this.beginingOfMonth.getMonth(),
        1 - this.beginingOfMonth.getDay(),
      );
      const lastDay = new Date(firstDay.getTime());
      lastDay.setDate(lastDay.getDate() + 7 * 6 - 1);

      let day = new Date(firstDay.getTime());
      const calendar = [];

      const events = await this.getEvents(firstDay, lastDay);
      this.events = parseEvents(events);

      const allEvents = this.calendarEvents(this.events);

      for (let r = 0; r < 6; r++) {
        calendar[r] = [];
        for (let i = 0; i < 7; i++) {
          const calendarDay = {
            day: day,
            events: allEvents[day.getTime()],
          };
          calendar[r][i] = calendarDay;
          day = new Date(day.getTime());
          day.setDate(day.getDate() + 1);
        }
      }

      this.calendar = calendar;
    },
    emitEvent(event, data) {
      this.$emit(event, data);
    },
    calendarEvents(events) {
      const eventToKeyEvent = e => {
        const startAt = new Date(e.startAt);
        return {
          key: new Date(
            startAt.getFullYear(),
            startAt.getMonth(),
            startAt.getDate(),
          ).getTime(),
          event: e,
        };
      };
      const agregateEvents = (acc, e) => {
        const events = acc[e.key] || [];
        events.push(e.event);
        acc[e.key] = events;
        return acc;
      };

      return events.map(eventToKeyEvent).reduce(agregateEvents, {});
    },
    nextMonth() {
      const nextMonth = new Date(this.endOfMonth.getTime());
      nextMonth.setDate(nextMonth.getDate() + 1);
      this.currentMonth = nextMonth.getMonth();
      this.currentYear = nextMonth.getFullYear();

      this.setupDates();
      this.loadCalendar();
      this.emitEvent('monthChanged', {
        month: this.currentMonth,
        year: this.currentYear,
      });
    },
    previousMonth() {
      const previousMonth = new Date(this.beginingOfMonth.getTime());
      previousMonth.setDate(previousMonth.getDate() - 1);
      this.currentMonth = previousMonth.getMonth();
      this.currentYear = previousMonth.getFullYear();

      this.setupDates();
      this.loadCalendar();
      this.emitEvent('monthChanged', {
        month: this.currentMonth,
        year: this.currentYear,
      });
    },
    openToday() {
      const today = new Date();
      this.currentMonth = today.getMonth();
      this.currentYear = today.getFullYear();

      this.setupDates();
      this.loadCalendar();
      this.emitEvent('monthChanged', {
        month: this.currentMonth,
        year: this.currentYear,
      });
    },
  },
};
</script>

<style lang="scss">
.month-title {
  color: #3d78bc;
  font-weight: 100;
  font-size: 23px;
  margin-bottom: 20px;
  padding-left: 0 !important;

  &:first-letter {
    text-transform: uppercase;
  }
}

.calendar-nav {
  display: inline-block;
  height: 35px;
  padding-right: 0 !important;

  .btn-today {
    min-width: 100px;
  }
}

.calendar {
  width: 100%;
  flex-flow: column;
  border: 1px solid #dfdfdf;
  padding: 0;
  border-bottom: none;

  .headers {
    text-align: center;
    color: #6f6f6f;
  }

  .row {
    margin: 0 0px;
    border-bottom: 1px solid #dfdfdf;
  }

  .col {
    padding: 0;
    width: calc(100% / 7);
    min-width: calc(100% / 7);
    max-width: calc(100% / 7);
    border-right: 1px solid #dfdfdf;

    &:last-child {
      border-right: none;
    }
  }

  .day {
    display: block;
    height: 150px;

    .day-title {
      text-align: center;
      color: #6f6f6f;
      display: block;
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 14px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      margin-top: 4px;
      margin-bottom: 4px;

      &:hover {
        background-color: #efefef;
        cursor: pointer;
      }
    }

    &.today {
      .day-title {
        background-color: rgb(106, 151, 185);
        color: #fff;

        &:hover {
          background-color: rgb(100, 139, 168);
        }
      }
    }

    .day-events {
      display: block;
      width: 100%;

      .event {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 5px;
        font-size: 13px;

        &:hover {
          background-color: #efefef;
          cursor: pointer;
        }
      }

      .event-modal {
        .event {
          font-size: 16px;
          padding: 3px 0;
        }
      }

      .more {
        color: rgb(32, 120, 186);
        font-size: 13px;
        text-align: center;
        margin-top: 5px;

        &:hover {
          background-color: #efefef;
          cursor: pointer;
        }
      }

      .event-marker {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: rgb(32, 120, 186);
        border-radius: 3px;
        margin-right: 5px;
      }
    }
  }

  .other-month {
    opacity: 0.6;
  }
}
</style>
