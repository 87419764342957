<script setup>
import SideMenu from '../components/SideMenu.vue';
import apiClient from '../util/api-client';
import getCurrentUser from '../util/current-user';
import eventBus from '../util/events/event-bus';
import sessionStorage from '../util/storage/session-storage';
</script>

<script>
export default {
  async mounted() {
    const result = await apiClient.users.getUser(this.currentUser.userId);

    if (result.ok) {
      this.user = result.data;
    }
  },
  data() {
    return {
      mainItems: [
        {
          label: 'Visão Geral',
          icon: 'bi bi-grid-1x2',
          url: '/',
        },
        {
          label: 'Agenda',
          icon: 'bi bi-calendar-event',
          url: '/agenda',
        },
        {
          label: 'Financeiro',
          icon: 'bi bi-cash-coin',
          url: '/finances',
        },
        {
          label: 'Pacientes',
          icon: 'bi bi-people',
          url: '/patients',
        },
      ],
      items: [
        {
          label: 'Clindy Premium',
          icon: 'bi bi-patch-check',
          url: '/tiers',
        },
        {
          label: 'Configurações',
          icon: 'bi bi-gear',
          url: '/users/edit',
        },
        {
          label: 'Saír',
          icon: 'bi bi-box-arrow-left',
          url: '/',
          command: () => {
            sessionStorage.clearToken();
            this.$router.push('/login');
          },
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return getCurrentUser();
    },
    filteredItems() {
      if (
        this.currentUser.accountType === 'staff' ||
        this.currentUser?.accountType === 'beta'
      ) {
        const filtredItems = this.items.filter(
          item => item.label !== 'Clindy Premium',
        );
        return filtredItems;
      }
      return this.items;
    },
  },
  methods: {
    menuClicked() {
      eventBus.$emit('menu-button-clicked');
    },
    executeCommand(item) {
      if (item.command) item.command();
    },
    accountTypeClass() {
      return this.currentUser.accountType === 'beta'
        ? 'bg-danger'
        : 'bg-warning';
    },
  },
};
</script>

<template>
  <div>
    <div class="topbar">
      <div class="topbar-content">
        <a class="menu-button" v-on:click="menuClicked">
          <i class="bi bi-list" style="font-size: 2rem; line-height: 60px"></i>
        </a>
        <div class="brand">
          <img src="/logo.svg" />
        </div>
        <span
          v-if="currentUser?.accountType != 'customer'"
          class="badge rounded-pill account-type-badge"
          :class="accountTypeClass()">
          {{ currentUser.accountType }}
        </span>
        <ul class="main-menu">
          <li v-for="item in mainItems" :key="item.label">
            <router-link :to="item.url" v-on:click="executeCommand(item)">
              <i :class="item.icon"></i>
              <span class="menu-label">{{ item.label }}</span>
            </router-link>
          </li>
        </ul>

        <ul class="topmenu">
          <li v-for="item in filteredItems" :key="item.label">
            <router-link :to="item.url" v-on:click="executeCommand(item)">
              <i :class="item.icon"></i>
              <span class="menu-label">{{ item.label }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="menu-container">
      <SideMenu :items="mainItems" />
    </div>
  </div>
</template>

<style lang="scss">
.menu-container {
  @media screen and (min-width: 600px) {
    display: none;
  }
}

.topbar {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  display: flex;
  flex-flow: row;
  background: var(--primary);
  height: 60px;
  padding: 0 20px;
  max-width: 100vw;
  justify-content: center;

  @media screen and (min-width: 600px) {
    padding: 0 60px;
  }

  .topbar-content {
    display: flex;
    flex-flow: row;
    max-width: 1200px;
    width: 100%;
  }

  .menu-label {
    display: inline-block;
    margin-left: 10px;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .menu-button {
    display: block;
    height: 60px;
    line-height: 60px;
    padding: 0 10px;
    color: #fff;
    border: none;
    font-size: 20px;

    @media screen and (max-width: 600px) {
      display: block;
    }

    @media screen and (min-width: 600px) {
      display: none !important;
    }

    &.menu-button-close {
      display: none !important;
    }
  }

  .topmenu,
  .main-menu {
    display: flex;
    flex-flow: row;
    list-style: none;
    flex-grow: 1;
    justify-content: flex-end;

    li {
      height: 60px;
      display: inline-block;

      a {
        text-decoration: none;
        display: block;
        line-height: 60px;
        height: 100%;
        color: #fff;
        padding: 0 20px;
        font-size: 17px;

        &:hover {
          background: #2c6db7;
        }
      }
    }
  }

  .topmenu {
    .menu-label {
      @media screen and (max-width: 1200px) {
        display: none !important;
      }
    }
  }

  .main-menu {
    justify-content: flex-start;
    flex-grow: 1;

    @media screen and (max-width: 600px) {
      display: none !important;
    }
  }
}

.brand {
  display: block;
  height: 60px;
  width: 120px;
  line-height: 60px;
}

.account-type-badge {
  height: 20px;
  margin-top: 30px;
  margin-left: 5px;
}
</style>
