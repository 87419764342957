<script setup>
import { nanoid } from 'nanoid';

import PaginatedList from '../../components/PaginatedList.vue';
import apiClient from '../../util/api-client';
</script>

<script>
export default {
  mounted() {
    this.term = this.$route.query.term;
  },
  data() {
    return {
      patientListKey: nanoid(),
      filter: null,
      patients: [],
      term: null,
    };
  },
  methods: {
    confirmDeletePatient(patient) {
      this.$confirm({
        message: `Excluir um paciente exclui também as consultas relacionadas. Deseja realmente excluir o paciente ${patient.name}?`,
        title: 'Atenção!',
        icon: 'pi pi-exclamation-circle',
        acceptClass: 'btn-danger',
        acceptLabel: 'Excluir',
        rejectLabel: 'Cancelar',
        onAccept: async () => {
          const result = await apiClient.patients.deletePatient(patient.id);
          if (result.ok) {
            this.$toast({
              severity: 'success',
              title: 'Sucesso',
              message: 'Paciente excluido com sucesso!',
              life: 3000,
            });
            this.reloadList();
          } else {
            this.$toast({
              severity: 'error',
              title: 'Erro',
              message: result.errors,
              life: 3000,
            });
          }
        },
      });
    },
    search() {
      this.$router.push({
        query: { term: this.term, page: 1, pageSize: this.pageSize },
      });
    },
    onRowSelect(patient) {
      this.$router.push(`/patients/${patient.id}`);
    },
    reloadList() {
      this.patientListKey = nanoid();
    },
  },
};
</script>

<template>
  <div class="card mb-4">
    <div class="card-body">
      <div class="container-fluid">
        <h3>Pacientes</h3>
        <PaginatedList
          apiPath="/patients"
          class="patients mt-4"
          :key="patientListKey">
          <template #empty>
            <div class="blankslate">
              <p>Sem pacientes cadastrados? Começe agora mesmo!</p>
              <button
                class="btn btn-primary"
                label="Adicionar Paciente"
                v-on:click="$router.push('/patients/create')">
                Adicionar Paciente
              </button>
            </div>
          </template>
          <template #header>
            <div class="row pb-3">
              <div class="col-6">
                <button
                  class="btn btn-primary mb-3"
                  label="Novo"
                  v-on:click="$router.push('/patients/create')">
                  Novo
                </button>
              </div>

              <div class="col-12 col-sm-6">
                <div class="input-group float-end">
                  <input
                    v-model="term"
                    type="text"
                    class="form-control"
                    @keyup.enter="search" />
                  <button class="btn btn-outline-secondary" v-on:click="search">
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template #item="patient">
            <div class="row pb-3 pt-3 patient">
              <div class="col-md-10">
                <div class="mb-2">
                  <router-link
                    :to="`/patients/${patient.id}`"
                    class="patient-name"
                    >{{ patient.name }}</router-link
                  >
                </div>
                <div><i class="bi bi-at"></i> {{ patient.email }}</div>
                <div>
                  <i class="bi bi-phone"></i>
                  {{ patient.phone }}
                </div>
              </div>
              <div class="col-md-2 mt-3 mt-md-0">
                <div class="float-md-end">
                  <button
                    class="btn btn-outline-primary me-1 btn-sm"
                    @click="$router.push(`/patients/${patient.id}/edit`)">
                    <i class="bi bi-pencil"></i>
                  </button>
                  <button
                    class="btn btn-outline-danger btn-sm"
                    @click="confirmDeletePatient(patient)">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </PaginatedList>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.patients {
  .item {
    &:first-child.patient {
      border-top: 1px solid #cfcfcf;
    }
  }
  .patient {
    border: 1px solid #cfcfcf;
    margin: 10px 0;
    border-radius: 5px;

    patient-name &:hover {
      background-color: #f6f6f6;
    }

    a.patient-name {
      font-size: 20px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
