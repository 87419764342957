<script setup>
import apiClient from '../../util/api-client';
import mixpanel from '../../util/mixpanel';
import sessionStorage from '../../util/storage/session-storage';
</script>

<script>
export default {
  beforeMount() {
    const token = sessionStorage.getToken();
    if (token) this.$router.push('/');
  },
  methods: {
    async login(e) {
      e.preventDefault();

      const errors = this.validate();
      this.errors = errors;

      if (Object.keys(this.errors).length === 0) {
        try {
          const { email, password } = this.user;
          const response = await apiClient.session.login(email, password);
          if (!response.created) {
            this.errors = response.errors;
          } else {
            mixpanel.track('loged-in', { $email: this.user.email });
            sessionStorage.storeToken(response.token);
            this.$router.push('/');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        mixpanel.track('login-form-error', { $email: this.user.email });
      }
    },
    getClass(field) {
      if (this.errors[field]) return 'p-invalid';
      return '';
    },
    validate() {
      const errors = {};

      if (!this.user.email) errors.email = 'Email é obrigatório';
      if (!this.user.password) errors.password = 'Senha é obrigatório';

      return errors;
    },
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      errors: {},
    };
  },
};
</script>

<template>
  <div class="login">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-4">Login</h5>
        <form @submit="login">
          <div class="mb-3">
            <label class="form-label" for="name">Email</label>
            <div class="input-group">
              <span class="input-group-text">
                <i class="bi bi-at"></i>
              </span>
              <input
                id="email"
                type="text"
                v-model="user.email"
                class="form-control"
                v-bind:class="getClass('email')" />
            </div>
            <small class="error-message" v-if="errors.email">{{
              $t(errors.email)
            }}</small>
          </div>

          <div class="mb-3">
            <label class="form-label" for="name">Senha</label>
            <div class="input-group">
              <span class="input-group-text">
                <i class="bi bi-key"></i>
              </span>
              <input
                id="password"
                type="password"
                v-model="user.password"
                class="form-control"
                v-bind:class="getClass('password')" />
            </div>
            <small class="error-message" v-if="errors.password">{{
              $t(errors.password)
            }}</small>
          </div>
          <button type="submit" class="btn btn-primary">Login</button>
          <router-link
            class="btn btn-outline-secondary ms-2 me-2"
            to="/create-account"
            >Criar Conta</router-link
          >
          <router-link class="btn btn-link" to="/request-recover"
            >Esqueci a senha</router-link
          >
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.login {
  display: flex;
  align-items: center;
  .card {
    width: 100%;
    max-width: 500px;
  }
}
</style>
