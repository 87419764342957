import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.MIXPANEL_TOKEN;

const env = process.env.NODE_ENV;

if (env === 'production') {
  mixpanel.init(mixpanelToken, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  });
}

export default {
  identify(userId, data) {
    if (env === 'production') {
      mixpanel.identify(userId);
      data['$email'] = data.email;
      data['$name'] = data.name;
      delete data.email;
      delete data.name;
      mixpanel.people.set(data);
    }
  },
  track(event, data) {
    if (env === 'production') {
      mixpanel.track(event, data);
    }
  },
};
