import Emiter from 'tiny-emitter';

export const eventEmitter = new Emiter();

function toast(options) {
  eventEmitter.emit('toast', options);
}

const ToastPlugin = {
  install(app) {
    app.config.globalProperties.$toast = toast;
  },
};

export default ToastPlugin;
