import axios from 'axios';

import ClientError from './client-error.js';

export default (baseUrl, getToken) => {
  async function listAppointments(startAt, endAt) {
    const start = `${startAt.getFullYear()}-${
      startAt.getMonth() + 1
    }-${startAt.getDate()}`;
    const end = `${endAt.getFullYear()}-${
      endAt.getMonth() + 1
    }-${endAt.getDate()}`;
    let url = `${baseUrl}/appointments?start=${start}&end=${end}`;

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on listing appointments', response);
      }
    }
  }

  async function findById(id) {
    let url = `${baseUrl}/appointments/${id}`;

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      const appointment = response.data;
      appointment.startAt = new Date(appointment.startAt);
      appointment.endAt = new Date(appointment.endAt);
      return { ok: true, data: appointment };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on getting appointment', response);
      }
    }
  }

  async function timeline(date, direction, page, pageSize) {
    const baseDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    let url = `${baseUrl}/appointments/timeline?date=${baseDate}&direction=${direction}&page=${page}&pageSize=${pageSize}`;

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on listing appointments', response);
      }
    }
  }

  async function delayedAppointments(page, pageSize) {
    let url = `${baseUrl}/appointments/late?page=${page}&pageSize=${pageSize}`;

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on listing appointments', response);
      }
    }
  }

  async function nextAppointments(page, pageSize) {
    let url = `${baseUrl}/appointments/next?page=${page}&pageSize=${pageSize}`;

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on listing appointments', response);
      }
    }
  }

  async function cancelAppointment(id, options) {
    let url = `${baseUrl}/appointments/${id}/cancel`;
    if (options.cancelAll) {
      url = `${url}?cancelAll=${options.cancelAll}`;
    } else if (options.cancelNext) {
      url = `${url}?cancelNext=${options.cancelNext}`;
    }

    try {
      await axios({
        method: 'patch',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on canceling appointment', response);
      }
    }
  }

  async function completeAppointment(id) {
    try {
      await axios({
        method: 'patch',
        url: `${baseUrl}/appointments/${id}/complete`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on completing appointment', response);
      }
    }
  }

  async function pendentAppointment(id) {
    try {
      await axios({
        method: 'patch',
        url: `${baseUrl}/appointments/${id}/pending`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on completing appointment', response);
      }
    }
  }

  async function rescheduleAppointment(
    id,
    newStartAt,
    newDuration,
    rescheduleNext,
  ) {
    try {
      await axios({
        method: 'put',
        url: `${baseUrl}/appointments/${id}/reschedule`,
        data: {
          newStartAt,
          newDuration,
          rescheduleNext,
        },
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on rescheduling appointment', response);
      }
    }
  }

  return {
    listAppointments,
    findById,
    timeline,
    cancelAppointment,
    completeAppointment,
    pendentAppointment,
    rescheduleAppointment,
    delayedAppointments,
    nextAppointments,
  };
};
