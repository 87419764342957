import axios from 'axios';

import ClientError from './client-error.js';

export default (baseUrl, getToken) => {
  async function updateUser(userData, password) {
    try {
      const response = await axios({
        method: 'put',
        url: `${baseUrl}/users/edit`,
        data: { currentPassword: password, userData },
        headers: {
          'x-auth': getToken(),
        },
      });
      return { ok: true, user: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on updating user', response);
      }
    }
  }

  async function getUser(id) {
    try {
      const response = await axios({
        method: 'get',
        url: `${baseUrl}/users/${id}`,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on getting user', response);
      }
    }
  }

  return {
    updateUser,
    getUser,
  };
};
