<template>
  <input ref="inputRef" type="text" />
</template>

<script>
import { watch } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number,
  },
  setup(props) {
    const options = {
      locale: 'pt-BR',
      currency: 'BRL',
      currencyDisplay: 'symbol',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: true,
      useGrouping: false,
      accountingSign: false,
    };

    const { inputRef, setValue } = useCurrencyInput(options);

    watch(
      () => props.modelValue,
      value => {
        setValue(value);
      },
    );
    return { inputRef };
  },
};
</script>
