<script setup>
import Quill from 'quill';
</script>

<template>
  <div ref="editor"></div>
</template>

<script>
export default {
  props: ['modelValue', 'placeholder'],
  data() {
    return {
      content: '',
    };
  },
  mounted() {
    if (!this.instance) {
      const toolbarOptions = [
        // [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote'], //'code-block'

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],

        ['clean'], // remove formatting button
      ];
      const options = {
        modules: {
          toolbar: toolbarOptions,
        },
        placeholder: this.placeholder,
        theme: 'snow',
      };

      this.instance = new Quill(this.$refs.editor, options);

      this.instance.on('text-change', () => {
        this.content = this.instance.root.innerHTML;
        this.$emit('update:modelValue', this.content);
      });
    }
  },
};
</script>
