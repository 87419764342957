import axios from 'axios';

import ClientError from './client-error.js';

export default (baseUrl, getToken) => {
  async function createPurchase(data) {
    try {
      const response = await axios({
        method: 'post',
        url: `${baseUrl}/purchases`,
        data: data,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, patient: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status === 400) {
        return { ok: false, errors: response.data };
      } else {
        throw new ClientError('Error on creating purchase', response);
      }
    }
  }

  return {
    createPurchase,
  };
};
