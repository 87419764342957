<script setup>
import Loader from '../../components/Loader.vue';
import apiClient from '../../util/api-client';
</script>

<script>
function parseEvents(events) {
  return events.map(e => {
    const startAt = new Date(e.startAt);
    const endAt = new Date(e.endAt);

    return {
      id: e.id,
      startAt: startAt,
      endAt: endAt,
      description: e.patientName,
      duration: (endAt.getTime() - startAt.getTime()) / 60000,
      status: e.status,
      patient: {
        id: e.patientId,
        name: e.patientName,
        phone: e.patientPhone,
        email: e.patientEmail,
      },
    };
  });
}

export default {
  props: ['eventBus'],
  beforeMount() {
    this.initialDate = new Date();
  },
  async mounted() {
    await this.loadData();
    await this.setupScroll();

    this.eventBus.on('force-update', async () => {
      this.days = {};
      this.futurePage = 0;
      this.pastPage = 0;
      await this.loadData();
      await this.setupScroll();
    });
  },
  data() {
    return {
      loadingNext: false,
      loadingPrevious: false,
      loading: false,
      initialDate: null,
      days: {},
      futurePage: 0,
      futureHasNext: true,
      pastPage: 0,
      pastHasNext: true,
      mainDay: null,
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.loadNext();
      await this.loadPrevious();

      this.loading = false;
    },
    async loadNext() {
      this.loadingNext = true;
      if (!this.futureHasNext) return;
      const page = this.futurePage + 1;
      const response = await apiClient.appointments.timeline(
        this.initialDate,
        'future',
        page,
        25,
      );
      if (response.ok) {
        const events = parseEvents(response.data.appointments);

        if (events.length > 0) {
          if (!this.mainDay) {
            this.mainDay = events[0].startAt.toISOString().slice(0, 10);
          }

          for (let i in events) {
            const event = events[i];
            const key = event.startAt.toISOString().slice(0, 10);
            const dayEvents = this.days[key] || [];
            dayEvents.push(event);
            this.days[key] = dayEvents;
          }

          this.futurePage = page;
        } else {
          this.futureHasNext = false;
        }
      }
      this.loadingNext = false;
    },
    async loadPrevious() {
      this.loadingPrevious = true;
      if (!this.pastHasNext) return;
      const page = this.pastPage + 1;
      const response = await apiClient.appointments.timeline(
        this.initialDate,
        'past',
        page,
        25,
      );
      if (response.ok) {
        const events = parseEvents(response.data.appointments);
        if (events.length > 0) {
          for (let i in events) {
            const event = events[i];
            const key = event.startAt.toISOString().slice(0, 10);
            const dayEvents = this.days[key] || [];
            dayEvents.unshift(event);
            this.days[key] = dayEvents;
          }

          this.pastPage = page;
        } else {
          this.pastHasNext = false;
        }
      }
      this.loadingPrevious = false;
    },
    buildDate(dateStr) {
      const date = new Date(dateStr);
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
      return date;
    },
    isToday(date) {
      const today = new Date();
      return today.toDateString() === date.toDateString();
    },
    setupScroll() {
      this.scrollToDay(this.mainDay);
      const el = this.$refs.timeline;
      let fetching = false;
      el.addEventListener('scroll', async () => {
        const maxScroll = el.scrollHeight - el.clientHeight;
        const threashould = 100;
        if (el.scrollTop < threashould && !fetching && this.pastHasNext) {
          fetching = true;
          await this.loadPrevious();
          fetching = false;
        } else if (
          el.scrollTop > maxScroll - threashould &&
          !fetching &&
          this.futureHasNext
        ) {
          fetching = true;
          await this.loadNext();
          fetching = false;
        }
      });
    },
    scrollToDay(dayKey, behavior = 'instant') {
      this.$nextTick(() => {
        const el = this.$refs[`day-${dayKey}`]?.[0];
        if (el) {
          el.scrollIntoView({ behavior: behavior });
        }
      });
    },
    eventClicked(event) {
      const _event = Object.assign({}, event);
      this.$emit('eventClicked', _event);
    },

    appointmentStatusClass(event) {
      if (
        event?.status == 'pending' &&
        new Date(event.startAt).getTime() < new Date().getTime()
      ) {
        return 'delayed';
      } else if (event?.status == 'complete') {
        return 'complete';
      }
    },
  },
  computed: {
    dayKeys() {
      return Object.keys(this.days).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime(),
      );
    },
  },
};
</script>

<template>
  <Loader v-if="loading" />
  <div
    v-if="!loading"
    class="timeline-schedule d-flex flex-column"
    ref="timeline">
    <div class="events">
      <div
        class="day"
        v-for="dayKey in dayKeys"
        :key="dayKey"
        :ref="`day-${dayKey}`">
        <div class="day-label">
          {{
            buildDate(dayKey).toLocaleDateString('pt-BR', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              weekday: 'long',
            })
          }}
          <span class="badge bg-primary" v-if="isToday(buildDate(dayKey))"
            >hoje</span
          >
        </div>
        <div
          class="event d-flex flex-row"
          v-for="event in days[dayKey]"
          :key="event.id"
          v-on:click="eventClicked(event)">
          <span class="marker" :class="appointmentStatusClass(event)"></span>
          <div class="event-card">
            <div class="info">
              <div class="title">
                {{ event.description }}
              </div>
              <div class="contact">
                <span> {{ event.patient.phone }}</span>
                <span> {{ event.patient.email }}</span>
              </div>
            </div>
            <div class="time">
              {{
                event.startAt.toLocaleTimeString('pt-BR', {
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
              <br />
              {{
                event.endAt.toLocaleTimeString('pt-BR', {
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }}
            </div>
          </div>
        </div>
      </div>
      <Loader v-if="loadingNext" />
    </div>
  </div>
</template>

<style lang="scss">
.timeline-schedule {
  overflow: scroll;
  height: calc(100vh - 110px);

  @media screen and (min-width: 400px) {
    height: calc(100vh - 200px);
  }

  a {
    cursor: pointer;
  }

  .day-label {
    color: var(--primary-dark);
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    &::first-letter {
      text-transform: uppercase;
    }

    .badge {
      color: #fff !important;
    }
  }

  .event {
    width: 100%;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
    border-left: none;
    overflow: hidden;
    cursor: pointer;

    .marker {
      display: block;
      min-height: 100%;
      width: 5px;
      background-color: var(--primary);

      &.delayed {
        border-left: 5px solid #e32b1e;
      }

      &.complete {
        border-left: 5px solid #037b03;
      }
    }

    .event-card {
      display: flex;
      flex-flow: row;
      width: 100%;
      padding: 10px;

      .time {
        font-size: 15px;
        font-weight: bold;
        color: #3e3e3e;
        font-variant-numeric: tabular-nums;
      }

      .info {
        flex-grow: 1;
      }

      .contact {
        display: flex;
        flex-flow: column;
        margin-top: 8px;
        font-size: 14px;
        color: #3e3e3e;
      }

      .title {
        color: #3e3e3e;
        font-weight: bold;
      }
    }
  }
}
</style>
