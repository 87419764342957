import { createRouter, createWebHistory } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import CreateAppointment from '../views/appointmets/CreateAppointment.vue';
import LateAppointments from '../views/appointmets/LateAppointments.vue';
import Schedule from '../views/appointmets/Schedule.vue';
import ListFinances from '../views/finance/FinancesList.vue';
import OverdueFinanceList from '../views/finance/OverdueFinanceList.vue';
import EditOrCreatePatientRecord from '../views/patient-records/EditOrCreatePatientRecord.vue';
import ShowPatientRecord from '../views/patient-records/ShowPatientRecord.vue';
import EditOrCreatePatient from '../views/patients/EditOrCreatePatient.vue';
import ListPatients from '../views/patients/ListPatients.vue';
import PatientView from '../views/patients/PatientView.vue';
import AllSubscriptionPlans from '../views/subscriptions/AllSubscriptionPlans.vue';
import CreateAccount from '../views/user/CreateAccount.vue';
import EditUser from '../views/user/EditUser.vue';
import Login from '../views/user/Login.vue';
import RequestRecover from '../views/user/RequestRecover.vue';
import ResetPassword from '../views/user/ResetPassword.vue';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    {
      path: '/',
      component: HomeView,
    },
    {
      path: '/agenda',
      component: Schedule,
    },
    {
      path: '/create-account',
      component: CreateAccount,
      meta: {
        public: true,
      },
    },
    {
      path: '/login',
      component: Login,
      meta: {
        public: true,
      },
    },
    {
      path: '/patients',
      component: ListPatients,
    },
    {
      path: '/patients/create',
      component: EditOrCreatePatient,
    },
    {
      path: '/patients/:id',
      component: PatientView,
    },
    {
      path: '/patients/:id/edit',
      component: EditOrCreatePatient,
    },
    {
      path: '/tiers',
      component: AllSubscriptionPlans,
    },
    {
      path: '/users/edit',
      component: EditUser,
    },
    {
      path: '/appointments/create/:step?',
      component: CreateAppointment,
    },
    {
      path: '/appointments/late',
      component: LateAppointments,
    },
    {
      path: '/finances',
      component: ListFinances,
    },
    {
      path: '/finances/overdue',
      component: OverdueFinanceList,
    },
    {
      path: '/patients/:patientId/appointments/:appointmentId/add-record',
      component: EditOrCreatePatientRecord,
    },
    {
      path: '/patients/:patientId/add-record',
      component: EditOrCreatePatientRecord,
    },
    {
      path: '/patients/:patientId/records/:recordId',
      component: ShowPatientRecord,
    },
    {
      path: '/request-recover',
      component: RequestRecover,
      meta: {
        public: true,
      },
    },
    {
      path: '/reset-password',
      component: ResetPassword,
      meta: {
        public: true,
      },
    },
  ],
});

export default router;
