import axios from 'axios';

import ClientError from './client-error.js';

export default baseUrl => {
  async function login(email, password) {
    try {
      const response = await axios({
        method: 'post',
        url: `${baseUrl}/sessions/signin`,
        data: { email, password },
      });

      return { created: true, token: response.data.token };
    } catch (error) {
      const response = error.response;
      if (response?.status === 400) {
        return { created: false, errors: response.data };
      } else {
        console.error(error);
        throw new ClientError('Error on creating session', response);
      }
    }
  }

  return {
    login,
  };
};
