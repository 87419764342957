<script setup>
import { nanoid } from 'nanoid';

import apiClient from '../util/api-client';

import Loader from './Loader.vue';
import Pagination from './Pagination.vue';
</script>

<template>
  <div class="container-fluid p-0">
    <Loader v-if="loading" />
    <slot name="header" v-if="!isEmpty"></slot>
    <div v-if="!loading" class="items">
      <slot name="empty" v-if="isEmpty"></slot>
      <div class="item" v-for="item in items" :key="item.id || nanoid()">
        <slot name="item" v-bind="item">
          {{ item }}
        </slot>
      </div>
    </div>

    <Pagination
      :url="pagePath"
      :current-page="currentPage"
      :page-size="pageSize"
      :total-items="totalItems" />
  </div>
</template>

<script>
export default {
  props: ['apiPath'],
  data() {
    return {
      loading: true,
      items: [],
      totalItems: null,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const res = await apiClient
        .client()
        .get(
          `${this.apiPath}?page=${this.currentPage}&pageSize=${this.pageSize}&term=${this.term}`,
        );
      this.items = res.data.records;
      this.totalItems = res.data.total;
      this.loading = false;
    },
  },
  computed: {
    isEmpty() {
      return this.items.length < 1;
    },
    pagePath() {
      return this.$route.path;
    },
    currentPage() {
      return this.$route.query.page || 1;
    },
    pageSize() {
      return this.$route.query.pageSize || 10;
    },
    term() {
      return this.$route.query.term || '';
    },
  },
  watch: {
    currentPage() {
      this.loadData();
    },
    term() {
      this.loadData();
    },
  },
};
</script>
