import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import * as VueI18n from 'vue-i18n';

import App from './App.vue';
import './assets/main.scss';
import DialogPlugin from './components/dialog/dialog-plugin';
import tooltip from './components/directives/tooltip';
import ToastPlugin from './components/toast/toast-plugin';
import messages from './messages';
import router from './router';

const env = process.env.NODE_ENV;

const i18n = VueI18n.createI18n({
  locale: 'pt-br',
  messages,
});

const app = createApp(App);

if (env === 'production') {
  Sentry.init({
    app,
    environment: env,
    dsn: 'https://6135fd926e83db624c905730da07ae59@o4505765492752384.ingest.sentry.io/4505765496750080',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', /^https:\/\/app\.clindy\.com\.br/],
  });
}

app.use(i18n);
app.use(ToastPlugin);
app.use(DialogPlugin);
app.use(router);
app.directive('tooltip', tooltip);

router.isReady().then(() => app.mount('#app'));
