<script>
export default {
  methods: {
    async redirectToWhatsapp() {
      const message = 'Olá, tenho interesse em assinar o Clindy Premium';
      const url = `https://wa.me/5547988009393?text=${encodeURI(message)}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<template>
  <div class="plan-container">
    <div class="card plan-card">
      <div class="card-body">
        <h4>Plano Premium</h4>
        <div class="card-content">
          <ul>
            <li>Gestão de Pacientes (Sem limites)</li>
            <li>Gestão de Consultas (Sem limites)</li>
            <li>Prontuário eletrônico.</li>
            <li>Gestão de pacotes e serviços</li>
            <li>Módulo financeiro</li>
          </ul>

          <div class="price">R$ 39,90</div>
          <button
            class="btn btn-primary plan-button col-12"
            v-on:click="redirectToWhatsapp()">
            Quero Assinar
          </button>
        </div>
      </div>
    </div>

    <div class="card plan-card">
      <div class="card-body">
        <h4>Plano Starter</h4>
        <div class="card-content">
          <ul>
            <li>Gestão de Pacientes (Limitado a 10 pacientes)</li>
            <li>Gestão de Consultas (Limitado a 20 consultas por mês)</li>
            <li>Prontuário eletrônico.</li>
            <li>Gestão de pacotes e serviços</li>
            <li>Módulo financeiro</li>
          </ul>
          <div class="price">Gratuito</div>
          <h3>Plano Atual</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.plan-container {
  @media screen and (max-width: 800px) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  @media screen and (min-width: 800px) {
    display: flex;
    flex-flow: row;
    justify-content: center;
  }

  .plan-card {
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    margin: 0 10px;
    max-width: 350px;
    padding-bottom: 30px;
    max-height: 600px;

    @media screen and (max-width: 800px) {
      margin: 10px 0px;
    }

    .p-card-content {
      height: 100%;
    }

    .card-content {
      display: flex;
      flex-flow: column;
      height: 100%;

      .price {
        text-align: center;
        font-size: 40px;
        margin-bottom: 20px;
        color: #3d78bc;
      }

      ul {
        flex: 1;

        li {
          margin: 10px 0;
        }
      }

      h3 {
        padding-bottom: 10px;
        text-align: center;
      }
    }
  }
}
</style>
