import axios from 'axios';

import ClientError from './client-error.js';

export default (baseUrl, getToken) => {
  async function listFinances(month) {
    let url = `${baseUrl}/finances`;
    if (month) {
      url = `${url}?month=${month}`;
    }
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });
      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on listing finances', response);
      }
    }
  }

  async function overdueRecords(page, pageSize) {
    let url = `${baseUrl}/finances/overdue?page=${page}&pageSize=${pageSize}`;

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on listing financial records', response);
      }
    }
  }

  async function monthlySummary(month) {
    let url = `${baseUrl}/finances/summary`;
    if (month) {
      url = `${url}?month=${month}`;
    }
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });
      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on getting finances summary', response);
      }
    }
  }

  async function overview(month) {
    let url = `${baseUrl}/finances/overview`;
    if (month) {
      url = `${url}?month=${month}`;
    }
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });
      return { ok: true, data: response.data };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on getting finances summary', response);
      }
    }
  }

  async function findById(id) {
    let url = `${baseUrl}/finances/${id}`;

    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      const financial = response.data;
      return { ok: true, data: financial };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on getting financial records', response);
      }
    }
  }

  async function paidFinancialRecord(id) {
    let url = `${baseUrl}/finances/${id}/paid`;
    try {
      await axios({
        method: 'patch',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on paid financial record', response);
      }
    }
  }

  async function unpaidFinancialRecord(id) {
    let url = `${baseUrl}/finances/${id}/unpaid`;
    try {
      await axios({
        method: 'patch',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on unpaid financial record', response);
      }
    }
  }

  async function deleteFinancialRecord(id) {
    let url = `${baseUrl}/finances/${id}/delete`;
    try {
      await axios({
        method: 'patch',
        url: url,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return {
          created: false,
          errors: response.data,
          code: response.status,
        };
      } else {
        throw new ClientError('Error on unpaid financial record', response);
      }
    }
  }

  async function updateFinancialRecord(id, financialRecord) {
    let url = `${baseUrl}/finances/${id}/edit`;
    try {
      await axios({
        method: 'put',
        url: url,
        data: financialRecord,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return { created: false, errors: response.data, code: response.status };
      } else {
        throw new ClientError('Error on update financial record', response);
      }
    }
  }

  async function createFinancialRecord(financialRecord) {
    let url = `${baseUrl}/finances/create`;
    try {
      await axios({
        method: 'post',
        url: url,
        data: financialRecord,
        headers: {
          'x-auth': getToken(),
        },
      });

      return { ok: true };
    } catch (error) {
      const response = error.response;
      if (response.status >= 400 && response.status < 500) {
        return { created: false, errors: response.data, code: response.status };
      } else {
        throw new ClientError('Error on update financial record', response);
      }
    }
  }

  return {
    listFinances,
    findById,
    paidFinancialRecord,
    unpaidFinancialRecord,
    deleteFinancialRecord,
    updateFinancialRecord,
    createFinancialRecord,
    monthlySummary,
    overview,
    overdueRecords,
  };
};
