import { Tooltip } from 'bootstrap';

export default {
  mounted(el) {
    el.setAttribute('data-bs-custom-class', 'cl-tooltip');
    el.tooltip = new Tooltip(el);
  },
  beforeUnmount(el) {
    el.tooltip.hide();
  },
};
