const tokenKey = 'auth-token';

function storeToken(token) {
  localStorage.setItem(tokenKey, token);
}

function getToken() {
  return localStorage.getItem(tokenKey);
}

function clearToken() {
  localStorage.removeItem(tokenKey);
}

export default {
  storeToken,
  getToken,
  clearToken,
};
