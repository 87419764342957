<template>
  <div class="pagination">
    <button
      class="btn btn-link"
      v-on:click="changePage(pagination.first)"
      v-if="pagination.current > pagination.first">
      {{ pagination.first.toString() }}
    </button>
    <span class="spacer" v-if="pagination.first + 1 < pagination.previous[0]"
      >...</span
    >
    <button
      v-for="i in pagination.previous"
      :key="i"
      class="btn btn-link"
      v-on:click="changePage(i)">
      {{ i.toString() }}
    </button>
    <button
      v-if="totalItems > pageSize"
      class="btn btn-link"
      disabled="disabled">
      {{ pagination.current.toString() }}
    </button>
    <button
      v-for="i in pagination.next"
      :key="i"
      class="btn btn-link"
      v-on:click="changePage(i)">
      {{ i.toString() }}
    </button>
    <span
      class="spacer"
      v-if="pagination.next[pagination.next.length - 1] < pagination.last - 1"
      >...</span
    >
    <button
      class="btn btn-link"
      v-on:click="changePage(pagination.last)"
      v-if="pagination.current < pagination.last">
      {{ pagination.last.toString() }}
    </button>
  </div>
</template>

<script>
function createPagination(currentPage, pageSize, total) {
  const firstPage = 1;
  const lastPage = Math.ceil(total / pageSize);

  const previous = [currentPage - 2, currentPage - 1].filter(
    i => i > firstPage,
  );
  const next = [currentPage + 1, currentPage + 2].filter(i => i < lastPage);

  return {
    first: firstPage,
    last: lastPage,
    current: currentPage,
    previous,
    next,
  };
}

export default {
  props: ['url', 'currentPage', 'pageSize', 'totalItems'],
  data() {
    return {
      pagination: {
        first: '',
        last: '',
        current: '',
        previous: [],
        next: [],
      },
    };
  },
  mounted() {
    this.pagination = createPagination(
      parseInt(this.currentPage),
      parseInt(this.pageSize),
      parseInt(this.totalItems),
    );
  },
  methods: {
    changePage(page) {
      const currentQuery = this.$route.query;
      delete currentQuery.page;
      delete currentQuery.pageSize;
      this.$router.push({
        path: this.url,
        query: { page: page, pageSize: this.pageSize, ...currentQuery },
      });
      this.pagination = createPagination(
        parseInt(page),
        parseInt(this.pageSize),
        parseInt(this.totalItems),
      );
    },
  },
  watch: {
    totalItems() {
      this.pagination = createPagination(
        parseInt(this.currentPage),
        parseInt(this.pageSize),
        parseInt(this.totalItems),
      );
    },
  },
};
</script>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;

  .spacer {
    display: inline-block;
    height: 44px;
    line-height: 44px;
    color: #4f46e5;
    opacity: 0.6;
  }
}
</style>
