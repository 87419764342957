<script setup>
import { Modal } from 'bootstrap';
import Emiter from 'tiny-emitter';

import AppointmentActions from '../../components/appointments/AppointmentActions.vue';
import MonthlySchedule from '../../components/schedule/MonthlySchedule.vue';
import TimelineSchedule from '../../components/schedule/TimelineSchedule.vue';
import WeeklySchedule from '../../components/schedule/WeeklySchedule.vue';
import apiClient from '../../util/api-client';
</script>

<script>
// this is a workarrownd to access the modal on beforeRouteLeave
// for some reason, this.eventModal is undefined on that.
let _eventModal;

export default {
  data() {
    return {
      type: 'weekly',
      eventModal: null,
      eventBus: new Emiter(),
      appointment: {
        startAt: new Date(),
        status: 'pending',
        patient: {
          name: '',
        },
      },
      rescheduling: {
        newDate: null,
        newTime: null,
        rescheduleNext: false,
      },
    };
  },
  mounted() {
    this.type = this.$route.query.type || 'weekly';

    if (this.smallScreen) {
      this.type = 'timeline';
    }

    const el = this.$refs.eventModal;
    if (!this.eventModal) this.eventModal = new Modal(el, {});

    _eventModal = this.eventModal; // setting the eventModal for workarround
  },
  beforeUnmount() {
    this.eventModal.hide();
  },
  beforeRouteLeave(_to, from, next) {
    if (_eventModal._isShown && window.history.state.back === from.path) {
      next(false);
      _eventModal.hide();
    } else {
      next();
    }
  },
  methods: {
    selectType(type) {
      this.type = type;
      this.$router.push({ query: { type: type } });
    },
    tabActiveClass(type) {
      if (this.type === type) return 'active';
    },
    async showEvent(appointment) {
      const result = await apiClient.appointments.findById(appointment.id);
      if (result.ok) this.appointment = result.data;
      this.eventModal.show();
    },
    getViewStatus(data, status) {
      if (status === 'pending' && data.getTime() < new Date().getTime()) {
        return 'delayed';
      }
      return status;
    },
    getStatusClass(status) {
      if (status === 'pending') return 'bg-warning';
      if (status === 'delayed') return 'bg-danger';
      if (status === 'complete') return 'bg-success';
      return 'bg-secondary';
    },

    closeEventModal() {
      this.eventModal.hide();
    },
    openEventModal() {
      this.eventModal.show();
    },
    triggerForceUpdate() {
      this.eventBus.emit('force-update');
    },
    selectTab(e) {
      e.preventDefault();
      const type = e.target.getAttribute('data-schedule-type');
      this.selectType(type);
    },
  },
  computed: {
    smallScreen() {
      return window.innerWidth < 400;
    },
  },
  watch: {
    type() {
      if (this.eventModal._isShown) {
        this.eventModal.hide();
      }
    },
  },
};
</script>

<template>
  <div class="schedule-container">
    <div class="card schedule">
      <div class="card-body">
        <ul
          class="nav nav-pills justify-content-start mb-4"
          v-if="!smallScreen">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="tabActiveClass('monthly')"
              href="#"
              data-schedule-type="monthly"
              v-on:click="selectTab"
              >Mês</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="tabActiveClass('weekly')"
              href="#"
              data-schedule-type="weekly"
              v-on:click="selectTab"
              >Semana</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="tabActiveClass('timeline')"
              href="#"
              data-schedule-type="timeline"
              v-on:click="selectTab"
              >Timeline</a
            >
          </li>
          <li class="nav-item flex-fill d-flex flex-row justify-content-end">
            <router-link class="btn btn-primary" to="/appointments/create"
              >Nova consulta</router-link
            >
          </li>
        </ul>

        <div class="d-flex flex-row" v-if="smallScreen">
          <router-link class="btn-rounded" to="/appointments/create"
            >+</router-link
          >
        </div>

        <MonthlySchedule
          v-if="type === 'monthly'"
          @eventClicked="showEvent"
          :eventBus="eventBus" />
        <WeeklySchedule
          v-if="type === 'weekly'"
          @eventClicked="showEvent"
          :eventBus="eventBus" />
        <TimelineSchedule
          v-if="type === 'timeline'"
          @eventClicked="showEvent"
          :eventBus="eventBus" />
      </div>
    </div>
    <div class="modal fade" tabindex="-1" ref="eventModal">
      <div class="modal-dialog modal-fullscreen-sm-down event-modal">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detalhes da Consulta</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex flex-column">
              <div class="event-card justify-content-between">
                <div class="d-flex flex-row justify-content-center">
                  <div class="d-flex flex-row margin-right-box">
                    <div class="day">
                      {{ appointment.startAt.getDate() }}
                    </div>
                    <div class="d-flex flex-column">
                      <div class="month">
                        {{
                          appointment.startAt
                            .toLocaleDateString('pt-BR', { month: 'long' })
                            .slice(0, 3)
                        }}
                      </div>
                      <div class="year">
                        {{ appointment.startAt.getFullYear() }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column margin-right-box">
                    <div class="time">
                      {{
                        appointment.startAt.toLocaleTimeString('pt-BR', {
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      }}
                    </div>
                    <div class="duration">
                      {{ (appointment.endAt - appointment.startAt) / 60000 }}
                      min
                    </div>
                  </div>
                  <div class="d-flex flex-column align-self-center">
                    <span
                      class="badge"
                      :class="
                        getStatusClass(
                          getViewStatus(
                            appointment.startAt,
                            appointment.status,
                          ),
                        )
                      ">
                      {{
                        $t(
                          getViewStatus(
                            appointment.startAt,
                            appointment.status,
                          ),
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="d-flex flex-row justify-content-center margin-top-box">
                  <AppointmentActions
                    :appointment="appointment"
                    @completeRequested="closeEventModal"
                    @completeRejected="openEventModal"
                    @setComplete="triggerForceUpdate"
                    @cancelRequested="closeEventModal"
                    @cancelRejected="openEventModal"
                    @setCanceled="triggerForceUpdate"
                    @pendingRequested="closeEventModal"
                    @pendingRejected="openEventModal"
                    @setPending="triggerForceUpdate"
                    @reschedulingOpened="closeEventModal"
                    @reschedulingClosed="openEventModal"
                    @rescheduled="triggerForceUpdate" />
                </div>
              </div>

              <hr />

              <div class="d-flex flex-row patient-card">
                <div class="person-icon">
                  <i class="bi bi-person-circle"></i>
                </div>
                <div class="d-flex flex-column">
                  <div class="name">
                    {{ appointment.patient.name }}
                  </div>
                  <div class="d-flex flex-column contact">
                    <span
                      ><i class="bi bi-envelope-at"></i>
                      {{ appointment.patient.email }}</span
                    >
                    <span
                      ><i class="bi bi-phone"></i>
                      {{ appointment.patient.phone }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.schedule-container {
  height: 100%;
  display: flex;
  flex: 1;

  .event-modal {
    .status {
      display: block;
      font-size: 12px;
      margin-left: 10px;
      max-width: 100px;
      margin: 0;
      margin-top: 10px;
    }

    .patient-card {
      padding: 0 20px;

      .person-icon {
        color: #3f3f3f;
        font-size: 40px;
        align-self: flex-start;
        text-align: center;
        margin-right: 20px;
      }

      .name {
        font-size: 20px;
        font-weight: bold;
      }

      .contact {
        color: #5f5f5f;
      }
    }

    .event-card {
      display: flex;
      flex-flow: row;
      width: 100%;
      padding: 0 20px;

      @media screen and (max-width: 420px) {
        flex-flow: column;
      }
      @media screen and (min-width: 420px) {
        flex-flow: row;
        justify-content: space-between;
      }

      .margin-right-box {
        margin-right: 20px;
      }

      .margin-top-box {
        @media screen and (max-width: 420px) {
          margin-top: 20px;
        }
      }

      .day {
        height: 40px;
        line-height: 40px;
        margin-right: 8px;
        font-size: 40px;
        font-weight: bold;
      }
      .month {
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        height: 20px;
      }
      .year {
        font-size: 15px;
        line-height: 20px;
        height: 20px;
        color: #7f7f7f;
      }

      .dot {
        display: block;
        height: 4px;
        width: 4px;
        background-color: black;
        border-radius: 2px;
        align-self: center;
        margin: 0 10px;
      }

      .time {
        height: 25px;
        line-height: 25px;
        font-size: 25px;
        align-self: center;
        font-weight: bold;
      }

      .duration {
        height: 15px;
        line-height: 15px;
        font-size: 15px;
        color: #7f7f7f;
        text-align: center;
      }
    }
  }

  .btn-rounded {
    background-color: var(--primary);
    color: #ffffff;
    width: 50px;
    height: 50px;
    padding: 0;
    line-height: 50px;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    position: fixed;
    z-index: 1000;
    right: 20px;
    bottom: 20px;
    box-shadow: 0px 0px 5px #9f9f9f;

    &:hover {
      background-color: var(--primary-dark);
    }
  }
}
</style>
