<script setup>
import Chart from 'chart.js/auto';

import Loader from '../components/Loader.vue';
import AppointmentCard from '../components/appointments/AppointmentCard.vue';
import FinanceActions from '../components/finance/FinanceActions.vue';
import apiClient from '../util/api-client';
import mixpanel from '../util/mixpanel';
</script>

<script>
export default {
  data() {
    return {
      financialOverview: [],
      nextAppointments: [],
      delayedAppointments: [],
      overdueFinancialRecords: [],
      loadingFinancialOverview: false,
      loadingNextAppointments: false,
      loadingDelayedAppointments: false,
      loadingOverdueFinancialRecords: false,
    };
  },
  async mounted() {
    this.initializeIncomeChart();
    this.initializeNextAppointments();
    this.initializeDelayedAppointments();
    this.initializeOverdueFinancialRecords();

    mixpanel.track('opened-dashboard');
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const localDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60000,
      );
      return localDate.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
    },
    async initializeNextAppointments() {
      this.loadingNextAppointments = true;
      this.nextAppointments = (
        await apiClient.appointments.nextAppointments(1, 3)
      ).data.appointments;
      this.loadingNextAppointments = false;
    },
    async initializeDelayedAppointments() {
      this.loadingDelayedAppointments = true;
      this.delayedAppointments = (
        await apiClient.appointments.delayedAppointments(1, 3)
      ).data.records;
      this.loadingDelayedAppointments = false;
    },
    async initializeOverdueFinancialRecords() {
      this.loadingOverdueFinancialRecords = true;
      const response = await apiClient.financial.overdueRecords(1, 5);
      this.overdueFinancialRecords = response.data.records;
      this.loadingOverdueFinancialRecords = false;
    },
    async initializeIncomeChart() {
      this.loadingFinancialOverview = true;
      const month = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
        .toString()
        .padStart(2, '0')}`;

      this.financialOverview = (await apiClient.financial.overview(month)).data;

      this.loadingFinancialOverview = false;

      this.financialOverview.pop();
      this.financialOverview.pop();

      const financialOverview = this.$refs.financialOverview;
      const labels = this.financialOverview.map(m => {
        const parts = m.monthYear.split('-');
        return `${parts[1]}-${parts[0]}`;
      });

      const paidIncome = {
        label: 'Recebido',
        data: this.financialOverview.map(m => m.paidIncome / 100),
        borderWidth: 1,
        backgroundColor: '#2e8b57',
        stack: 'income',
      };

      const pendingIncome = {
        label: 'A receber',
        data: this.financialOverview.map(m => m.pendingIncome / 100),
        borderWidth: 1,
        backgroundColor: '#7ac49a',
        stack: 'income',
      };

      new Chart(financialOverview, {
        type: 'bar',

        data: {
          labels: labels,
          datasets: [paidIncome, pendingIncome],
        },
        options: {
          locale: 'pt-BR',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                callback: function (value) {
                  return 'R$ ' + value;
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.dataset.label}: R$ ${context.formattedValue}`;
                },
              },
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      });
    },
    formatCurrency(valueInCents, abs = true) {
      const value = abs ? Math.abs(valueInCents / 100) : valueInCents / 100;
      var formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return formater.format(value);
    },
    hasItems(list) {
      return list.length > 0;
    },
  },
};
</script>

<template>
  <div class="dashboard-container">
    <div class="dashboard">
      <div class="d-md-flex flex-row h-100">
        <div class="col-md-6 d-flex flex-column">
          <div class="card m-2">
            <div class="card-header">
              <h5>Próximas Consultas</h5>
            </div>
            <div class="card-body">
              <Loader v-if="loadingNextAppointments" />
              <div v-if="!loadingNextAppointments">
                <AppointmentCard
                  v-for="appointment in nextAppointments"
                  :key="appointment.id"
                  :appointment="appointment"
                  @appointmentUpdated="initializeNextAppointments" />
                <div class="alert-box" v-if="!hasItems(nextAppointments)">
                  <i class="bi bi-exclamation-circle"></i>
                  <p>Não há proximas consultas!</p>
                  <router-link
                    class="btn btn-primary"
                    to="/appointments/create/1"
                    >Adicionar Consulta</router-link
                  >
                </div>
                <div class="pt-2 text-center" v-if="hasItems(nextAppointments)">
                  <router-link to="/agenda?type=timeline"
                    >Ver todas</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="card m-2">
            <div class="card-header">
              <h5>Consultas Atrasadas</h5>
            </div>
            <div class="card-body">
              <Loader v-if="loadingDelayedAppointments" />
              <div v-if="!loadingDelayedAppointments">
                <AppointmentCard
                  v-for="appointment in delayedAppointments"
                  :key="appointment.id"
                  :appointment="appointment"
                  @appointmentUpdated="initializeDelayedAppointments" />
                <div v-if="!hasItems(delayedAppointments)" class="empty-box">
                  <i class="bi bi-patch-check"></i>
                  <p>Tudo certo! <br />Sem consultas atrasadas.</p>
                </div>
                <div
                  class="pt-2 text-center"
                  v-if="hasItems(delayedAppointments)">
                  <router-link to="/appointments/late">Ver todas</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex flex-column">
          <div class="card m-2">
            <div class="card-header">
              <h5>Receitas</h5>
            </div>
            <div class="card-body">
              <Loader v-if="loadingFinancialOverview" />
              <div class="financial-preview">
                <canvas ref="financialOverview"></canvas>
              </div>
            </div>
          </div>
          <div class="card m-2">
            <div class="card-header">
              <h5>Lançamentos em Atraso</h5>
            </div>
            <div class="card-body">
              <Loader v-if="loadingOverdueFinancialRecords" />
              <div v-if="!loadingOverdueFinancialRecords">
                <div
                  v-for="record in overdueFinancialRecords"
                  :key="record.id"
                  class="d-md-flex flex-row overdue-financial-records align-items-center"
                  :class="record.type">
                  <div class="flex-grow-1">{{ record.description }}</div>
                  <div class="d-flex align-items-center">
                    <div class="me-2">
                      {{ formatDate(record.dueDate) }}
                    </div>
                    <div class="flex-grow-1 me-3" :class="record.type">
                      {{ formatCurrency(record.value) }}
                    </div>
                    <div>
                      <FinanceActions
                        :finance="record"
                        :clean-button="true"
                        @financeUpdated="initializeOverdueFinancialRecords()" />
                    </div>
                  </div>
                </div>
                <div
                  v-if="!hasItems(overdueFinancialRecords)"
                  class="empty-box">
                  <i class="bi bi-patch-check"></i>
                  <p>
                    Tudo certo! <br />Sem lançamentos financeiros atrasados.
                  </p>
                </div>
                <div
                  class="pt-2 text-center"
                  v-if="hasItems(overdueFinancialRecords)">
                  <router-link to="/finances/overdue">Ver todas</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.dashboard {
  min-height: 100%;

  .financial-preview {
    padding: 10px 20px;
  }

  .overdue-financial-records {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #efefef;
    border-left: 3px solid #e32b1e;
    border-radius: 3px;

    .income {
      color: #4f8d71;
    }

    .outcome {
      color: #e32b1e;
    }
  }

  .empty-box,
  .alert-box {
    padding: 20px 10px;
    text-align: center;
    .bi {
      font-size: 40px;
    }
    p {
      margin-top: 20px;
    }
  }

  .empty-box {
    .bi {
      color: #4f8d71;
    }
  }

  .alert-box {
    .bi {
      color: #e4b81a;
    }
  }
}
</style>
